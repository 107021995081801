import {
  Logout as LogoutIcon,
  OpenInNew as OpenInNewIcon
} from "@mui/icons-material"
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Select,
  styled,
  Toolbar,
  Typography
} from "@mui/material"
import { ReactComponent as EverDrivenLogo } from "assets/images/everDrivenPnWhite.svg"
import { Link as NavLink } from "react-router-dom"
import { DRAWER_WIDTH, HEADER_HEIGHT } from "utils/constants"

import { IDrawerContainer } from "./Header.types"

export const Container = styled(Box)({
  display: "flex",
  height: `${HEADER_HEIGHT}px`
})

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: `${theme.zIndex.drawer + 1}`,
  height: `${HEADER_HEIGHT}`,
  width: "100%"
}))

export const StyledToolbar = styled(Toolbar)({
  display: "flex",
  height: `${HEADER_HEIGHT}px`
})

export const DisplayNameNotificationsContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-start"
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(0.2)
}))

export const DrawerContainer = styled(Box)<IDrawerContainer>(({ theme }) => ({
  width: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    flexShrink: "0"
  }
}))

export const DrawerDesktop = styled(Drawer)(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  display: "block",
  [theme.breakpoints.up("md")]: {
    display: "block"
  },
  [theme.breakpoints.down("md")]: {
    display: "none"
  },
  "& .MuiDrawer-paper": { boxSizing: "border-box", width: DRAWER_WIDTH }
}))

export const DrawerMobile = styled(Drawer)(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  [theme.breakpoints.down("md")]: {
    display: "block"
  },
  [theme.breakpoints.up("md")]: {
    display: "none"
  },
  "& .MuiDrawer-paper": { boxSizing: "border-box", width: DRAWER_WIDTH }
}))

export const NameDisplay = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}))

export const NotificationIcon = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))

export const EverDrivenIcon = styled(EverDrivenLogo)(({ theme }) => ({
  height: theme.spacing(2.5),
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1)
}))

export const ProviderName = styled(Typography)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold}`,
  color: `${theme.palette.common.white}`
}))

interface StyledListItemButtonProps {
  isActive?: boolean
}

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isActive"
})<StyledListItemButtonProps>(({ theme, isActive }) => {
  const backgroundColor = isActive
    ? `${theme.palette.primary.main}38`
    : undefined
  const boxSizing = "border-box"
  const borderRight = isActive
    ? `5px solid ${theme.palette.primary.main}`
    : undefined

  return {
    "&:hover": {
      backgroundColor
    },
    backgroundColor,
    boxSizing,
    borderRight,
    alignItems: "flex-end"
  }
})

export const StyledList = styled(List)(({ theme }) => ({
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(0)
}))

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: "MuseoSans700",
  height: theme.spacing(6),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}))

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  fontFamily: "MuseoSans700",
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&:hover": {
    color: `${theme.palette.primary.main}`
  }
}))

export const StyledResourcesNavLink = styled(NavLink)(({ theme }) => ({
  fontFamily: "MuseoSans700",
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&:hover": {
    color: `${theme.palette.primary.main}`
  },

  [theme.breakpoints.up("md")]: {
    display: "none"
  }
}))

export const RightBoxLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0),
  height: theme.spacing(6),
  [theme.breakpoints.up("md")]: {
    display: "none"
  }
}))

export const RightBoxLinkBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(5),
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(0.5)
}))

export const StyledSignOutBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "auto"
})

export const RightBoxLinkBoxSignOut = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(5)
}))

export const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  fontSize: theme.typography.body1.fontSize,
  bottom: 1,
  position: "relative"
}))

export const StyledLogoutIcon = styled(LogoutIcon)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  bottom: -3,
  position: "relative"
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  paddingLeft: theme.spacing(0.2),
  width: "100%",
  textTransform: "capitalize",
  color: theme.palette.common.black,
  fontFamily: "MuseoSans700",
  ".MuiOutlinedInput-notchedOutline": {
    border: "0px solid transparent"
  },

  [theme.breakpoints.up("md")]: {
    display: "none"
  }
}))
