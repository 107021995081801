import { FC, useRef } from "react"

import ScrollToTop from "../../shared/ScrollToTop/ScrollToTop"
import StyledBody, { StyledViewContent } from "./Body.styles"
import { IBody } from "./Body.types"

const Body: FC<IBody> = ({ open, children = [] }) => {
  const parentRef = useRef<HTMLElement | null>(null)

  return (
    <StyledBody open={open}>
      <StyledViewContent ref={parentRef}>
        <ScrollToTop parentRef={parentRef}>{children}</ScrollToTop>
      </StyledViewContent>
    </StyledBody>
  )
}

export default Body
