import { GraphQLClient } from "graphql-request"
import useUserContext from "hooks/useUserContext"

const graphQLClient = new GraphQLClient(
  `${process.env.REACT_APP_BASE_API_URL}`,
  {
    headers: {
      "Content-Type": "application/json"
    }
  }
)

const useGetGqlClient = () => {
  const { loginToken } = useUserContext()
  graphQLClient.setHeader("Authorization", `Bearer ${loginToken}`)

  return graphQLClient
}

export default useGetGqlClient
