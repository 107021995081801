import { useCallback, useState } from "react"

const getStorageValue = <T,>(key: string, defaultValue: T) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key)
    const initial = saved !== null ? JSON.parse(saved) : defaultValue
    if (saved === null) localStorage.setItem(key, JSON.stringify(defaultValue))

    return initial as T
  }

  localStorage.setItem(key, JSON.stringify(defaultValue))
  return defaultValue
}

const useLocalStorage = <T,>(key: string, defaultValue: T) => {
  const currentLocalStorageValue = getStorageValue(key, defaultValue)
  const [localStorageValue, setLocalStorageValue] = useState<T>(
    currentLocalStorageValue
  )

  const handleValueChange = useCallback(
    (newValue: T) => {
      setLocalStorageValue(() => newValue)
      localStorage.setItem(key, JSON.stringify(newValue))
    },
    [key]
  )

  const handleRemoveValue = useCallback(() => {
    setLocalStorageValue(() => defaultValue)
    localStorage.removeItem(key)
  }, [defaultValue, key])

  return { value: localStorageValue, handleValueChange, handleRemoveValue }
}

export default useLocalStorage
