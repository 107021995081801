import Auth from "auth/Auth"
import useGetGqlClient from "graphql/hooks/useGetGqlClient"
import { Variables } from "graphql-request"
import useUserContext from "hooks/useUserContext"
import { HttpCodes } from "utils/constants"

export type ErrorType = {
  response?: {
    status?: number
  }
}

export const useCustomFetcher = <TData, TVariables extends Variables>(
  query: string
): ((variables?: TVariables) => Promise<TData>) => {
  const client = useGetGqlClient()
  const { setLoginToken } = useUserContext()

  return async (variables?: TVariables) => {
    try {
      const clientResponse = await client.request<TData, Variables>(
        query,
        variables
      )

      return clientResponse
    } catch (requestError) {
      if (
        (requestError as ErrorType)?.response?.status ===
          HttpCodes.unAuthorized ||
        (requestError as ErrorType)?.response?.status === HttpCodes.forbidden
      ) {
        try {
          await Auth.tokenRenewal()
          const token = Auth.getToken()
          setLoginToken(token)
          client.setHeader("Authorization", `Bearer ${token}`)

          const requestResponse = await client.request<TData, Variables>(
            query,
            variables
          )

          return requestResponse
        } catch (renewalOrRequestError) {
          await Auth.logoutAfterError()
          throw renewalOrRequestError
        }
      }
      throw requestError
    }
  }
}
