import {
  GetProviderNameQuery,
  OutputProviderPayloadType
} from "generated/graphql"

interface IHeaderAdapter {
  dataAPI?: GetProviderNameQuery
}

const headerAdapter = ({ dataAPI }: IHeaderAdapter) => {
  const providerInfo =
    dataAPI?.provider?.payload ?? ({} as OutputProviderPayloadType)
  const { providerName } = providerInfo

  return {
    providerName
  }
}

export default headerAdapter
