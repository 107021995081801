import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions
} from "@tanstack/react-query"
import { useCustomFetcher } from "hooks/useCustomFetcher"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  Decimal: { input: any; output: any }
  Long: { input: any; output: any }
  NonEmptyString: { input: any; output: any }
}

export type CsvDownloadType = {
  __typename?: "CsvDownloadType"
  data?: Maybe<Scalars["String"]["output"]>
  filename?: Maybe<Scalars["String"]["output"]>
}

export enum DriverSortFieldsType {
  DriverCode = "DRIVER_CODE",
  DriverFirstName = "DRIVER_FIRST_NAME",
  DriverLastName = "DRIVER_LAST_NAME",
  IsEligible = "IS_ELIGIBLE",
  Mdd = "MDD",
  VehicleColor = "VEHICLE_COLOR"
}

export type DriverType = {
  __typename?: "DriverType"
  commands?: Maybe<Array<Maybe<OutputCommandType>>>
  dataVersion: Scalars["Long"]["output"]
  downloadRuns?: Maybe<CsvDownloadType>
  downloadRunsV2?: Maybe<CsvDownloadType>
  keyType?: Maybe<Scalars["String"]["output"]>
  keyValue?: Maybe<Scalars["String"]["output"]>
  mrmMsgType?: Maybe<Scalars["String"]["output"]>
  mrmProvider?: Maybe<Scalars["String"]["output"]>
  payload?: Maybe<OutputDriverPayloadType>
  reports?: Maybe<Array<Maybe<OutputReportType>>>
  runs?: Maybe<PaginatedRunType>
  runsV2?: Maybe<PaginatedRunV2Type>
  schemaVersion?: Maybe<Scalars["String"]["output"]>
  trips?: Maybe<PaginatedTripType>
}

export type DriverTypeDownloadRunsArgs = {
  endDate: Scalars["DateTime"]["input"]
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type DriverTypeDownloadRunsV2Args = {
  endDate: Scalars["DateTime"]["input"]
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type DriverTypeRunsArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  endDate: Scalars["DateTime"]["input"]
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type DriverTypeRunsV2Args = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  endDate: Scalars["DateTime"]["input"]
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type DriverTypeTripsArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
}

export type InputDriverFilterDateRangeType = {
  end: Scalars["DateTime"]["input"]
  start: Scalars["DateTime"]["input"]
}

export type InputDriverFilterType = {
  eligibleDistricts?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  eligibleFlag?: InputMaybe<Scalars["Boolean"]["input"]>
  excludeDriverIDs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  runRange?: InputMaybe<InputDriverFilterDateRangeType>
}

export type InternalApiResponseType = {
  __typename?: "InternalApiResponseType"
  isSuccess: Scalars["Boolean"]["output"]
  message?: Maybe<Scalars["String"]["output"]>
  status?: Maybe<Scalars["String"]["output"]>
  statusCode: Scalars["Int"]["output"]
}

export enum OfferPickupsType {
  Am = "AM",
  Pm = "PM"
}

export enum OfferSortFieldsType {
  DriverName = "DRIVER_NAME",
  DropoffAddress = "DROPOFF_ADDRESS",
  PickupAddress = "PICKUP_ADDRESS",
  SrName = "SR_NAME",
  StartDate = "START_DATE"
}

export type OffersApiResultItemType = {
  __typename?: "OffersApiResultItemType"
  id?: Maybe<Scalars["String"]["output"]>
  success: Scalars["Boolean"]["output"]
}

export type OffersResponseType = {
  __typename?: "OffersResponseType"
  isSuccess: Scalars["Boolean"]["output"]
  message?: Maybe<Scalars["String"]["output"]>
  results?: Maybe<Array<Maybe<OffersApiResultItemType>>>
  status?: Maybe<Scalars["String"]["output"]>
  statusCode: Scalars["Int"]["output"]
}

export type OutputCommandType = {
  __typename?: "OutputCommandType"
  action?: Maybe<Scalars["String"]["output"]>
  createdAtUtc: Scalars["DateTime"]["output"]
  reason?: Maybe<Scalars["String"]["output"]>
  source?: Maybe<Scalars["String"]["output"]>
  user?: Maybe<Scalars["String"]["output"]>
}

export type OutputDriverPayloadType = {
  __typename?: "OutputDriverPayloadType"
  deleteFlag: Scalars["Boolean"]["output"]
  driverCode?: Maybe<Scalars["String"]["output"]>
  eligibleDistricts?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  eligibleFlag: Scalars["Boolean"]["output"]
  firstName?: Maybe<Scalars["String"]["output"]>
  lastName?: Maybe<Scalars["String"]["output"]>
  mDDCode?: Maybe<Scalars["String"]["output"]>
  picURL?: Maybe<Scalars["String"]["output"]>
  providerGUID?: Maybe<Scalars["String"]["output"]>
  vehicleInfo?: Maybe<OutputDriverVehicleInfoType>
}

export type OutputDriverVehicleInfoType = {
  __typename?: "OutputDriverVehicleInfoType"
  color?: Maybe<Scalars["String"]["output"]>
  licensePlate?: Maybe<Scalars["String"]["output"]>
  make?: Maybe<Scalars["String"]["output"]>
  model?: Maybe<Scalars["String"]["output"]>
}

export type OutputEquipmentItem = {
  __typename?: "OutputEquipmentItem"
  count: Scalars["Int"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type OutputGeoCoordinate = {
  __typename?: "OutputGeoCoordinate"
  latitude?: Maybe<Scalars["Int"]["output"]>
  longitude?: Maybe<Scalars["Int"]["output"]>
}

export type OutputLocation = {
  __typename?: "OutputLocation"
  address1?: Maybe<Scalars["String"]["output"]>
  address2?: Maybe<Scalars["String"]["output"]>
  city?: Maybe<Scalars["String"]["output"]>
  coordinates?: Maybe<OutputGeoCoordinate>
  country?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  state?: Maybe<Scalars["String"]["output"]>
  zip?: Maybe<Scalars["String"]["output"]>
}

export type OutputLocationTimePair = {
  __typename?: "OutputLocationTimePair"
  location?: Maybe<OutputTripLocation>
  time: Scalars["DateTime"]["output"]
}

export type OutputMemberPii = {
  __typename?: "OutputMemberPII"
  clientMemberCode?: Maybe<Scalars["String"]["output"]>
  firstName?: Maybe<Scalars["String"]["output"]>
  lastName?: Maybe<Scalars["String"]["output"]>
}

export type OutputProviderPayloadType = {
  __typename?: "OutputProviderPayloadType"
  deleteFlag: Scalars["Boolean"]["output"]
  externalId?: Maybe<Scalars["String"]["output"]>
  externalSource?: Maybe<Scalars["String"]["output"]>
  providerCode?: Maybe<Scalars["String"]["output"]>
  providerName?: Maybe<Scalars["String"]["output"]>
}

export type OutputReportType = {
  __typename?: "OutputReportType"
  contentType?: Maybe<Scalars["String"]["output"]>
  lastModified: Scalars["DateTime"]["output"]
  reportName?: Maybe<Scalars["String"]["output"]>
  uri?: Maybe<Scalars["String"]["output"]>
}

export type OutputRunDriverInfo = {
  __typename?: "OutputRunDriverInfo"
  driverCode?: Maybe<Scalars["String"]["output"]>
  driverGender?: Maybe<Scalars["String"]["output"]>
  driverName?: Maybe<Scalars["String"]["output"]>
  driverPicURL?: Maybe<Scalars["String"]["output"]>
  driverSubstituteFlag: Scalars["Boolean"]["output"]
  vehicleColor?: Maybe<Scalars["String"]["output"]>
  vehicleLicense?: Maybe<Scalars["String"]["output"]>
  vehicleMake?: Maybe<Scalars["String"]["output"]>
  vehicleModel?: Maybe<Scalars["String"]["output"]>
}

export type OutputRunPayload = {
  __typename?: "OutputRunPayload"
  districts?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  driverGUID?: Maybe<Scalars["String"]["output"]>
  driverInfo?: Maybe<OutputRunDriverInfo>
  dueDateTLT: Scalars["DateTime"]["output"]
  dueDateTimeUTC: Scalars["DateTime"]["output"]
  dueTimeTLT: Scalars["DateTime"]["output"]
  miles: Scalars["Decimal"]["output"]
  routeName?: Maybe<Scalars["String"]["output"]>
  runState?: Maybe<Scalars["String"]["output"]>
  stops?: Maybe<Array<Maybe<OutputStop>>>
  timeZone?: Maybe<Scalars["String"]["output"]>
  trips?: Maybe<Array<Maybe<OutputTripInfo>>>
  vehicleRunID: Scalars["Int"]["output"]
}

export type OutputStop = {
  __typename?: "OutputStop"
  dOTripGUIDs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  dueDateTLT: Scalars["DateTime"]["output"]
  dueDateTimeUTC: Scalars["DateTime"]["output"]
  dueTimeTLT: Scalars["DateTime"]["output"]
  location?: Maybe<OutputLocation>
  order: Scalars["Int"]["output"]
  pUTripGUIDs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
}

export type OutputSubscriptionRunDriverInfoType = {
  __typename?: "OutputSubscriptionRunDriverInfoType"
  driverCode?: Maybe<Scalars["String"]["output"]>
  driverGender?: Maybe<Scalars["String"]["output"]>
  driverName?: Maybe<Scalars["String"]["output"]>
  driverPicURL?: Maybe<Scalars["String"]["output"]>
  driverSubstituteFlag?: Maybe<Scalars["String"]["output"]>
  vehicleColor?: Maybe<Scalars["String"]["output"]>
  vehicleLicense?: Maybe<Scalars["String"]["output"]>
  vehicleMake?: Maybe<Scalars["String"]["output"]>
  vehicleModel?: Maybe<Scalars["String"]["output"]>
}

export type OutputSubscriptionRunOfferType = {
  __typename?: "OutputSubscriptionRunOfferType"
  offerStatus?: Maybe<Scalars["String"]["output"]>
  offeredToProviderCode?: Maybe<Scalars["String"]["output"]>
  offeredToProviderName?: Maybe<Scalars["String"]["output"]>
  offeredToProviderUTC?: Maybe<Scalars["String"]["output"]>
}

export type OutputSubscriptionRunPayloadType = {
  __typename?: "OutputSubscriptionRunPayloadType"
  districts?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  driverGUID?: Maybe<Scalars["String"]["output"]>
  driverInfo?: Maybe<OutputSubscriptionRunDriverInfoType>
  endDate: Scalars["DateTime"]["output"]
  pickupAMPM?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  routeName?: Maybe<Scalars["String"]["output"]>
  schedules?: Maybe<Array<Maybe<OutputSubscriptionRunSchedule>>>
  startDate: Scalars["DateTime"]["output"]
  subscriptionRunId?: Maybe<Scalars["String"]["output"]>
  subscriptionTrips?: Maybe<
    Array<Maybe<OutputSubscriptionRunSubscriptionTripType>>
  >
  substituteDriverInfo?: Maybe<
    Array<Maybe<OutputSubscriptionRunDriverInfoType>>
  >
  timeZone?: Maybe<Scalars["String"]["output"]>
  tripTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
}

export type OutputSubscriptionRunSchedule = {
  __typename?: "OutputSubscriptionRunSchedule"
  dayOfWeek?: Maybe<Scalars["String"]["output"]>
  estimatedMiles: Scalars["Float"]["output"]
  estimatedPayAmount: Scalars["Float"]["output"]
  firstPickUp?: Maybe<OutputSubscriptionRunScheduleStop>
  lastDropOff?: Maybe<OutputSubscriptionRunScheduleStop>
  members?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  numberOfStops: Scalars["Int"]["output"]
  stops?: Maybe<Array<Maybe<OutputSubscriptionRunScheduleStop>>>
  tripType?: Maybe<Scalars["String"]["output"]>
}

export type OutputSubscriptionRunScheduleStop = {
  __typename?: "OutputSubscriptionRunScheduleStop"
  doSubscriptionTripGUIDs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  dueTimeTLT?: Maybe<Scalars["String"]["output"]>
  isDropOff: Scalars["Boolean"]["output"]
  isPickUp: Scalars["Boolean"]["output"]
  location?: Maybe<OutputSubscriptionRunScheduleStopLocation>
  order: Scalars["Int"]["output"]
  puSubscriptionTripGUIDs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  stopNumber: Scalars["Int"]["output"]
}

export type OutputSubscriptionRunScheduleStopLocation = {
  __typename?: "OutputSubscriptionRunScheduleStopLocation"
  address1?: Maybe<Scalars["String"]["output"]>
  address2?: Maybe<Scalars["String"]["output"]>
  city?: Maybe<Scalars["String"]["output"]>
  coordinates?: Maybe<OutputSubscriptionRunScheduleStopLocationCoordinates>
  country?: Maybe<Scalars["String"]["output"]>
  facilityGuid?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  personalLocation: Scalars["Boolean"]["output"]
  state?: Maybe<Scalars["String"]["output"]>
  zip?: Maybe<Scalars["String"]["output"]>
}

export type OutputSubscriptionRunScheduleStopLocationCoordinates = {
  __typename?: "OutputSubscriptionRunScheduleStopLocationCoordinates"
  latitude: Scalars["Int"]["output"]
  longitude: Scalars["Int"]["output"]
}

export type OutputSubscriptionRunSubscriptionTripMember = {
  __typename?: "OutputSubscriptionRunSubscriptionTripMember"
  clientMemberCode?: Maybe<Scalars["String"]["output"]>
  firstName?: Maybe<Scalars["String"]["output"]>
  lastName?: Maybe<Scalars["String"]["output"]>
}

export type OutputSubscriptionRunSubscriptionTripType = {
  __typename?: "OutputSubscriptionRunSubscriptionTripType"
  doStopNotes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  endDate?: Maybe<Scalars["String"]["output"]>
  equipment?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  memberGUID?: Maybe<Scalars["String"]["output"]>
  memberPII?: Maybe<OutputSubscriptionRunSubscriptionTripMember>
  populationTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  puStopNotes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  requiresMonitor?: Maybe<Scalars["String"]["output"]>
  riderCapacities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  serviceLevel?: Maybe<Scalars["String"]["output"]>
  startDate?: Maybe<Scalars["String"]["output"]>
  subscriptionTripGUID?: Maybe<Scalars["String"]["output"]>
  subscriptionTripNotes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  subscriptionTripNumber: Scalars["Int"]["output"]
  subscriptionTripState?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripContacts = {
  __typename?: "OutputTripContacts"
  contactId?: Maybe<Scalars["String"]["output"]>
  deleteFlag: Scalars["Boolean"]["output"]
  emailAddress?: Maybe<Scalars["String"]["output"]>
  mobilePhone?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  parentAppAccessFlag: Scalars["Boolean"]["output"]
  relationship?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripCoordinates = {
  __typename?: "OutputTripCoordinates"
  latitude: Scalars["Int"]["output"]
  longitude: Scalars["Int"]["output"]
}

export type OutputTripDriverInfo = {
  __typename?: "OutputTripDriverInfo"
  driverGUID?: Maybe<Scalars["String"]["output"]>
  driverGender?: Maybe<Scalars["String"]["output"]>
  driverName?: Maybe<Scalars["String"]["output"]>
  driverPicURL?: Maybe<Scalars["String"]["output"]>
  driverSubstituteFlag: Scalars["Boolean"]["output"]
  vehicleColor?: Maybe<Scalars["String"]["output"]>
  vehicleLicense?: Maybe<Scalars["String"]["output"]>
  vehicleMake?: Maybe<Scalars["String"]["output"]>
  vehicleModel?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripInfo = {
  __typename?: "OutputTripInfo"
  dOStopNotes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  equipment?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  memberGUID?: Maybe<Scalars["String"]["output"]>
  memberPII?: Maybe<OutputMemberPii>
  pUStopNotes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  passengerCapacity?: Maybe<Scalars["String"]["output"]>
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  requiresMonitor: Scalars["Boolean"]["output"]
  serviceLevel?: Maybe<Scalars["String"]["output"]>
  tripGUID?: Maybe<Scalars["String"]["output"]>
  tripNotes?: Maybe<Scalars["String"]["output"]>
  tripNumber?: Maybe<Scalars["Int"]["output"]>
  tripState?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripLocation = {
  __typename?: "OutputTripLocation"
  address1?: Maybe<Scalars["String"]["output"]>
  address2?: Maybe<Scalars["String"]["output"]>
  city?: Maybe<Scalars["String"]["output"]>
  coordinates?: Maybe<OutputTripCoordinates>
  country?: Maybe<Scalars["String"]["output"]>
  facilityGuid?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  personalLocation: Scalars["Boolean"]["output"]
  state?: Maybe<Scalars["String"]["output"]>
  zip?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripMemberContacts = {
  __typename?: "OutputTripMemberContacts"
  contacts?: Maybe<Array<Maybe<OutputTripContacts>>>
}

export type OutputTripMemberPii = {
  __typename?: "OutputTripMemberPII"
  clientMemberCode?: Maybe<Scalars["String"]["output"]>
  firstName?: Maybe<Scalars["String"]["output"]>
  lastName?: Maybe<Scalars["String"]["output"]>
}

export type OutputTripPayloadType = {
  __typename?: "OutputTripPayloadType"
  district?: Maybe<Scalars["String"]["output"]>
  driverInfo?: Maybe<OutputTripDriverInfo>
  dropOffLocation?: Maybe<OutputTripLocation>
  dropoffTLT: Scalars["DateTime"]["output"]
  dueDateTLT: Scalars["DateTime"]["output"]
  dueDateTimeUTC: Scalars["DateTime"]["output"]
  dueTimeTLT: Scalars["DateTime"]["output"]
  equipment?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  isEmulated: Scalars["Boolean"]["output"]
  memberContacts?: Maybe<OutputTripMemberContacts>
  memberGUID?: Maybe<Scalars["String"]["output"]>
  memberPII?: Maybe<OutputTripMemberPii>
  onsiteTLT: Scalars["DateTime"]["output"]
  passengerCapacity?: Maybe<Scalars["String"]["output"]>
  pickUpLocation?: Maybe<OutputTripLocation>
  pickupTLT: Scalars["DateTime"]["output"]
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  requiresMonitor: Scalars["Boolean"]["output"]
  routeName?: Maybe<Scalars["String"]["output"]>
  scheduledDropOffTLT: Scalars["DateTime"]["output"]
  scheduledDropOffUTC: Scalars["DateTime"]["output"]
  serviceLevel?: Maybe<Scalars["String"]["output"]>
  state?: Maybe<Scalars["String"]["output"]>
  timeZone?: Maybe<Scalars["String"]["output"]>
  tripNumber: Scalars["Int"]["output"]
  vehicleRunID: Scalars["Int"]["output"]
  vehicleRunInfo?: Maybe<OutputTripVehicleRunInfo>
}

export type OutputTripVehicleRunInfo = {
  __typename?: "OutputTripVehicleRunInfo"
  dOStopOrder: Scalars["Int"]["output"]
  miles: Scalars["Float"]["output"]
  pUStopOrder: Scalars["Int"]["output"]
  runStatus?: Maybe<Scalars["String"]["output"]>
  tripGUIDs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  updateTimeUTC: Scalars["DateTime"]["output"]
}

export type PaginatedDriverType = {
  __typename?: "PaginatedDriverType"
  count: Scalars["Int"]["output"]
  endOfList: Scalars["Boolean"]["output"]
  results?: Maybe<Array<Maybe<DriverType>>>
  totalItemCount: Scalars["Int"]["output"]
}

export type PaginatedOfferType = {
  __typename?: "PaginatedOfferType"
  count: Scalars["Int"]["output"]
  endOfList: Scalars["Boolean"]["output"]
  results?: Maybe<Array<Maybe<SubscriptionRun>>>
  totalItemCount: Scalars["Int"]["output"]
}

export type PaginatedRunType = {
  __typename?: "PaginatedRunType"
  count: Scalars["Int"]["output"]
  endOfList: Scalars["Boolean"]["output"]
  results?: Maybe<Array<Maybe<RunType>>>
  totalItemCount: Scalars["Int"]["output"]
}

export type PaginatedRunV2Type = {
  __typename?: "PaginatedRunV2Type"
  count: Scalars["Int"]["output"]
  endOfList: Scalars["Boolean"]["output"]
  results?: Maybe<Array<Maybe<RunV2Type>>>
  totalItemCount: Scalars["Int"]["output"]
}

export type PaginatedTripType = {
  __typename?: "PaginatedTripType"
  count: Scalars["Int"]["output"]
  endOfList: Scalars["Boolean"]["output"]
  results?: Maybe<Array<Maybe<TripType>>>
  totalItemCount: Scalars["Int"]["output"]
}

export type ProviderType = {
  __typename?: "ProviderType"
  acceptedOffers?: Maybe<PaginatedOfferType>
  dataVersion: Scalars["Long"]["output"]
  downloadRuns?: Maybe<CsvDownloadType>
  downloadRunsV2?: Maybe<CsvDownloadType>
  keyType?: Maybe<Scalars["String"]["output"]>
  keyValue?: Maybe<Scalars["String"]["output"]>
  mrmMsgType?: Maybe<Scalars["String"]["output"]>
  mrmProvider?: Maybe<Scalars["String"]["output"]>
  offer?: Maybe<SubscriptionRun>
  openOffers?: Maybe<PaginatedOfferType>
  payload?: Maybe<OutputProviderPayloadType>
  run?: Maybe<RunType>
  runV2?: Maybe<RunV2Type>
  runs?: Maybe<PaginatedRunType>
  runsV2?: Maybe<PaginatedRunV2Type>
  schemaVersion?: Maybe<Scalars["String"]["output"]>
  trip?: Maybe<TripType>
  trips?: Maybe<PaginatedTripType>
}

export type ProviderTypeAcceptedOffersArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  pickup?: InputMaybe<Array<InputMaybe<OfferPickupsType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<OfferSortFieldsType>
}

export type ProviderTypeDownloadRunsArgs = {
  driverIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  endDate: Scalars["DateTime"]["input"]
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type ProviderTypeDownloadRunsV2Args = {
  driverIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  endDate: Scalars["DateTime"]["input"]
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type ProviderTypeOfferArgs = {
  subscriptionRunId: Scalars["ID"]["input"]
}

export type ProviderTypeOpenOffersArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  pickup?: InputMaybe<Array<InputMaybe<OfferPickupsType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<OfferSortFieldsType>
}

export type ProviderTypeRunArgs = {
  runId: Scalars["Int"]["input"]
}

export type ProviderTypeRunV2Args = {
  runId: Scalars["Int"]["input"]
}

export type ProviderTypeRunsArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  driverIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  endDate: Scalars["DateTime"]["input"]
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type ProviderTypeRunsV2Args = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  driverIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  endDate: Scalars["DateTime"]["input"]
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  runStatus?: InputMaybe<Array<InputMaybe<RunStatusesType>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<RunSortFieldsType>
  startDate: Scalars["DateTime"]["input"]
}

export type ProviderTypeTripArgs = {
  tripId: Scalars["ID"]["input"]
}

export type ProviderTypeTripsArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
}

export type RootMutation = {
  __typename?: "RootMutation"
  assignToOffers?: Maybe<OffersResponseType>
  changeDriver?: Maybe<Scalars["Boolean"]["output"]>
  changeDriverV2?: Maybe<InternalApiResponseType>
  changeOffersDriver?: Maybe<OffersResponseType>
  createUser?: Maybe<Scalars["Boolean"]["output"]>
  createUserV2?: Maybe<InternalApiResponseType>
  declineOffers?: Maybe<OffersResponseType>
}

export type RootMutationAssignToOffersArgs = {
  driverId: Scalars["ID"]["input"]
  offerIds: Array<Scalars["ID"]["input"]>
  reason?: InputMaybe<Scalars["String"]["input"]>
}

export type RootMutationChangeDriverArgs = {
  driverId: Scalars["ID"]["input"]
  reason?: InputMaybe<Scalars["String"]["input"]>
  runIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationChangeDriverV2Args = {
  driverId: Scalars["ID"]["input"]
  reason?: InputMaybe<Scalars["String"]["input"]>
  runIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationChangeOffersDriverArgs = {
  driverId: Scalars["ID"]["input"]
  offerIds: Array<Scalars["ID"]["input"]>
  reason?: InputMaybe<Scalars["String"]["input"]>
}

export type RootMutationCreateUserArgs = {
  mrmProvider: Scalars["ID"]["input"]
  user: UserInputType
}

export type RootMutationCreateUserV2Args = {
  mrmProvider: Scalars["ID"]["input"]
  user: UserInputType
}

export type RootMutationDeclineOffersArgs = {
  mrmProvider: Scalars["ID"]["input"]
  offerIds: Array<Scalars["ID"]["input"]>
  reason?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQuery = {
  __typename?: "RootQuery"
  driver?: Maybe<DriverType>
  drivers?: Maybe<PaginatedDriverType>
  provider?: Maybe<ProviderType>
  providers?: Maybe<Array<Maybe<ProviderType>>>
}

export type RootQueryDriverArgs = {
  driverId: Scalars["ID"]["input"]
  mrmProvider: Scalars["ID"]["input"]
}

export type RootQueryDriversArgs = {
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  filter?: InputMaybe<InputDriverFilterType>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  mrmProvider: Scalars["ID"]["input"]
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  sortBy?: InputMaybe<DriverSortFieldsType>
}

export type RootQueryProviderArgs = {
  mrmProvider: Scalars["ID"]["input"]
  providerId: Scalars["ID"]["input"]
}

export type RootQueryProvidersArgs = {
  mrmProvider: Scalars["ID"]["input"]
}

export enum RunSortFieldsType {
  DriverCode = "DRIVER_CODE",
  DriverName = "DRIVER_NAME",
  DropoffAddress = "DROPOFF_ADDRESS",
  DropoffDate = "DROPOFF_DATE",
  Id = "ID",
  Miles = "MILES",
  Passengers = "PASSENGERS",
  PickupAddress = "PICKUP_ADDRESS",
  PickupDate = "PICKUP_DATE",
  SrName = "SR_NAME",
  Status = "STATUS",
  Stops = "STOPS",
  Vehicle = "VEHICLE"
}

export enum RunStatusesType {
  Accepted = "ACCEPTED",
  Active = "ACTIVE",
  AtStop = "AT_STOP",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  NoShow = "NO_SHOW",
  Scheduled = "SCHEDULED",
  ToStop = "TO_STOP"
}

export type RunType = {
  __typename?: "RunType"
  districts?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  driver?: Maybe<DriverType>
  driverName?: Maybe<Scalars["String"]["output"]>
  equipment?: Maybe<Array<Maybe<OutputEquipmentItem>>>
  firstPickup?: Maybe<OutputLocationTimePair>
  id: Scalars["Int"]["output"]
  lastDropoff?: Maybe<OutputLocationTimePair>
  miles: Scalars["Float"]["output"]
  passengers: Scalars["Int"]["output"]
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  sRName?: Maybe<Scalars["String"]["output"]>
  status?: Maybe<Scalars["String"]["output"]>
  stops: Scalars["Int"]["output"]
  trips?: Maybe<Array<Maybe<TripType>>>
  vehicleMake?: Maybe<Scalars["String"]["output"]>
}

export type RunV2Type = {
  __typename?: "RunV2Type"
  dataVersion: Scalars["Long"]["output"]
  equipment?: Maybe<Array<Maybe<OutputEquipmentItem>>>
  firstPickup?: Maybe<OutputStop>
  keyType?: Maybe<Scalars["String"]["output"]>
  keyValue?: Maybe<Scalars["String"]["output"]>
  lastDropoff?: Maybe<OutputStop>
  mrmProvider?: Maybe<Scalars["String"]["output"]>
  passengers: Scalars["Int"]["output"]
  payload?: Maybe<OutputRunPayload>
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  schemaVersion?: Maybe<Scalars["String"]["output"]>
  stops: Scalars["Int"]["output"]
}

export type SubscriptionRun = {
  __typename?: "SubscriptionRun"
  dataVersion: Scalars["Long"]["output"]
  earliestPickup?: Maybe<OutputSubscriptionRunSchedule>
  equipment?: Maybe<Array<Maybe<OutputEquipmentItem>>>
  keyType?: Maybe<Scalars["String"]["output"]>
  keyValue?: Maybe<Scalars["String"]["output"]>
  latestDropoff?: Maybe<OutputSubscriptionRunSchedule>
  mrmMsgType?: Maybe<Scalars["String"]["output"]>
  mrmProvider?: Maybe<Scalars["String"]["output"]>
  offer?: Maybe<OutputSubscriptionRunOfferType>
  payload?: Maybe<OutputSubscriptionRunPayloadType>
  requirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  schemaVersion?: Maybe<Scalars["String"]["output"]>
  students?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  tripDays?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
}

export type TripType = {
  __typename?: "TripType"
  dataVersion: Scalars["Long"]["output"]
  keyType?: Maybe<Scalars["String"]["output"]>
  keyValue?: Maybe<Scalars["String"]["output"]>
  mrmProvider?: Maybe<Scalars["String"]["output"]>
  payload?: Maybe<OutputTripPayloadType>
  schemaVersion?: Maybe<Scalars["String"]["output"]>
}

export type UserInputType = {
  emailAddress: Scalars["NonEmptyString"]["input"]
  firstName: Scalars["NonEmptyString"]["input"]
  isAdministrator: Scalars["Boolean"]["input"]
  lastName: Scalars["NonEmptyString"]["input"]
}

export type AssignToOffersMutationVariables = Exact<{
  driverId: Scalars["ID"]["input"]
  offerIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type AssignToOffersMutation = {
  __typename?: "RootMutation"
  assignToOffers?: {
    __typename?: "OffersResponseType"
    isSuccess: boolean
    statusCode: number
    status?: string | null
    message?: string | null
    results?: Array<{
      __typename?: "OffersApiResultItemType"
      id?: string | null
      success: boolean
    } | null> | null
  } | null
}

export type ChangeDriverMutationVariables = Exact<{
  driverId: Scalars["ID"]["input"]
  runIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type ChangeDriverMutation = {
  __typename?: "RootMutation"
  changeDriver?: boolean | null
}

export type ChangeOffersDriverMutationVariables = Exact<{
  driverId: Scalars["ID"]["input"]
  offerIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type ChangeOffersDriverMutation = {
  __typename?: "RootMutation"
  changeOffersDriver?: {
    __typename?: "OffersResponseType"
    isSuccess: boolean
    statusCode: number
    status?: string | null
    message?: string | null
    results?: Array<{
      __typename?: "OffersApiResultItemType"
      id?: string | null
      success: boolean
    } | null> | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  user: UserInputType
}>

export type CreateUserMutation = {
  __typename?: "RootMutation"
  createUser?: boolean | null
}

export type DownloadDriverDetailsQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  driverId: Scalars["ID"]["input"]
  sortBy?: InputMaybe<RunSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  startDate: Scalars["DateTime"]["input"]
  endDate: Scalars["DateTime"]["input"]
  runStatus?: InputMaybe<
    Array<InputMaybe<RunStatusesType>> | InputMaybe<RunStatusesType>
  >
}>

export type DownloadDriverDetailsQuery = {
  __typename?: "RootQuery"
  driver?: {
    __typename?: "DriverType"
    downloadRunsV2?: {
      __typename?: "CsvDownloadType"
      data?: string | null
    } | null
  } | null
}

export type DownloadDriverRunsQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  driverId: Scalars["ID"]["input"]
  startDate: Scalars["DateTime"]["input"]
  endDate: Scalars["DateTime"]["input"]
}>

export type DownloadDriverRunsQuery = {
  __typename?: "RootQuery"
  driver?: {
    __typename?: "DriverType"
    downloadRuns?: {
      __typename?: "CsvDownloadType"
      filename?: string | null
      data?: string | null
    } | null
  } | null
}

export type DownloadProviderRunsQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  startDate: Scalars["DateTime"]["input"]
  endDate: Scalars["DateTime"]["input"]
  sortBy?: InputMaybe<RunSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  driverName?: InputMaybe<Scalars["String"]["input"]>
  driverIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >
  runStatus?: InputMaybe<
    Array<InputMaybe<RunStatusesType>> | InputMaybe<RunStatusesType>
  >
}>

export type DownloadProviderRunsQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    downloadRuns?: {
      __typename?: "CsvDownloadType"
      filename?: string | null
      data?: string | null
    } | null
  } | null
}

export type GetAcceptedOffersQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
  pickup?: InputMaybe<
    Array<InputMaybe<OfferPickupsType>> | InputMaybe<OfferPickupsType>
  >
  sortBy?: InputMaybe<OfferSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetAcceptedOffersQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    acceptedOffers?: {
      __typename?: "PaginatedOfferType"
      count: number
      totalItemCount: number
      endOfList: boolean
      results?: Array<{
        __typename?: "SubscriptionRun"
        keyValue?: string | null
        students?: Array<string | null> | null
        earliestPickup?: {
          __typename?: "OutputSubscriptionRunSchedule"
          firstPickUp?: {
            __typename?: "OutputSubscriptionRunScheduleStop"
            dueTimeTLT?: string | null
          } | null
        } | null
        latestDropoff?: {
          __typename?: "OutputSubscriptionRunSchedule"
          lastDropOff?: {
            __typename?: "OutputSubscriptionRunScheduleStop"
            dueTimeTLT?: string | null
          } | null
        } | null
        payload?: {
          __typename?: "OutputSubscriptionRunPayloadType"
          routeName?: string | null
          startDate: any
          driverGUID?: string | null
          driverInfo?: {
            __typename?: "OutputSubscriptionRunDriverInfoType"
            driverName?: string | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetDriverByIdQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  driverId: Scalars["ID"]["input"]
}>

export type GetDriverByIdQuery = {
  __typename?: "RootQuery"
  driver?: {
    __typename?: "DriverType"
    payload?: {
      __typename?: "OutputDriverPayloadType"
      firstName?: string | null
      lastName?: string | null
      mDDCode?: string | null
      picURL?: string | null
    } | null
  } | null
}

export type GetDriverRunsByIdQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  driverId: Scalars["ID"]["input"]
  sortBy?: InputMaybe<RunSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  startDate: Scalars["DateTime"]["input"]
  endDate: Scalars["DateTime"]["input"]
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  runStatus?: InputMaybe<
    Array<InputMaybe<RunStatusesType>> | InputMaybe<RunStatusesType>
  >
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type GetDriverRunsByIdQuery = {
  __typename?: "RootQuery"
  driver?: {
    __typename?: "DriverType"
    runsV2?: {
      __typename?: "PaginatedRunV2Type"
      totalItemCount: number
      results?: Array<{
        __typename?: "RunV2Type"
        keyValue?: string | null
        passengers: number
        stops: number
        firstPickup?: {
          __typename?: "OutputStop"
          dueTimeTLT: any
          dueDateTimeUTC: any
          location?: {
            __typename?: "OutputLocation"
            address1?: string | null
          } | null
        } | null
        lastDropoff?: {
          __typename?: "OutputStop"
          dueTimeTLT: any
          location?: {
            __typename?: "OutputLocation"
            address1?: string | null
          } | null
        } | null
        payload?: {
          __typename?: "OutputRunPayload"
          runState?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetDriversQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
  sortBy?: InputMaybe<DriverSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  filter?: InputMaybe<InputDriverFilterType>
}>

export type GetDriversQuery = {
  __typename?: "RootQuery"
  drivers?: {
    __typename?: "PaginatedDriverType"
    count: number
    totalItemCount: number
    endOfList: boolean
    results?: Array<{
      __typename?: "DriverType"
      keyValue?: string | null
      payload?: {
        __typename?: "OutputDriverPayloadType"
        firstName?: string | null
        lastName?: string | null
        eligibleFlag: boolean
        driverCode?: string | null
        mDDCode?: string | null
        vehicleInfo?: {
          __typename?: "OutputDriverVehicleInfoType"
          make?: string | null
          model?: string | null
          color?: string | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetDriversIdsQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
  sortBy?: InputMaybe<DriverSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  filter?: InputMaybe<InputDriverFilterType>
}>

export type GetDriversIdsQuery = {
  __typename?: "RootQuery"
  drivers?: {
    __typename?: "PaginatedDriverType"
    results?: Array<{
      __typename?: "DriverType"
      keyValue?: string | null
    } | null> | null
  } | null
}

export type GetDriversNamesIDsQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  filter?: InputMaybe<InputDriverFilterType>
}>

export type GetDriversNamesIDsQuery = {
  __typename?: "RootQuery"
  drivers?: {
    __typename?: "PaginatedDriverType"
    totalItemCount: number
    results?: Array<{
      __typename?: "DriverType"
      keyValue?: string | null
      payload?: {
        __typename?: "OutputDriverPayloadType"
        firstName?: string | null
        lastName?: string | null
        driverCode?: string | null
      } | null
    } | null> | null
  } | null
}

export type GetOfferQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  subscriptionRunId: Scalars["ID"]["input"]
}>

export type GetOfferQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    offer?: {
      __typename?: "SubscriptionRun"
      tripDays?: Array<string | null> | null
      offer?: {
        __typename?: "OutputSubscriptionRunOfferType"
        offerStatus?: string | null
      } | null
      earliestPickup?: {
        __typename?: "OutputSubscriptionRunSchedule"
        firstPickUp?: {
          __typename?: "OutputSubscriptionRunScheduleStop"
          dueTimeTLT?: string | null
        } | null
      } | null
      latestDropoff?: {
        __typename?: "OutputSubscriptionRunSchedule"
        lastDropOff?: {
          __typename?: "OutputSubscriptionRunScheduleStop"
          dueTimeTLT?: string | null
        } | null
      } | null
      payload?: {
        __typename?: "OutputSubscriptionRunPayloadType"
        routeName?: string | null
        driverGUID?: string | null
        districts?: Array<string | null> | null
        startDate: any
        endDate: any
        driverInfo?: {
          __typename?: "OutputSubscriptionRunDriverInfoType"
          driverName?: string | null
        } | null
        schedules?: Array<{
          __typename?: "OutputSubscriptionRunSchedule"
          estimatedMiles: number
          estimatedPayAmount: number
          stops?: Array<{
            __typename?: "OutputSubscriptionRunScheduleStop"
            order: number
            dueTimeTLT?: string | null
            puSubscriptionTripGUIDs?: Array<string | null> | null
            doSubscriptionTripGUIDs?: Array<string | null> | null
            location?: {
              __typename?: "OutputSubscriptionRunScheduleStopLocation"
              address1?: string | null
              city?: string | null
              state?: string | null
              zip?: string | null
            } | null
          } | null> | null
          firstPickUp?: {
            __typename?: "OutputSubscriptionRunScheduleStop"
            puSubscriptionTripGUIDs?: Array<string | null> | null
            doSubscriptionTripGUIDs?: Array<string | null> | null
          } | null
        } | null> | null
        subscriptionTrips?: Array<{
          __typename?: "OutputSubscriptionRunSubscriptionTripType"
          subscriptionTripGUID?: string | null
          memberGUID?: string | null
          requiresMonitor?: string | null
          requirements?: Array<string | null> | null
          equipment?: Array<string | null> | null
          memberPII?: {
            __typename?: "OutputSubscriptionRunSubscriptionTripMember"
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type GetOpenOffersQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
  pickup?: InputMaybe<
    Array<InputMaybe<OfferPickupsType>> | InputMaybe<OfferPickupsType>
  >
  sortBy?: InputMaybe<OfferSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetOpenOffersQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    openOffers?: {
      __typename?: "PaginatedOfferType"
      count: number
      totalItemCount: number
      endOfList: boolean
      results?: Array<{
        __typename?: "SubscriptionRun"
        keyValue?: string | null
        students?: Array<string | null> | null
        tripDays?: Array<string | null> | null
        earliestPickup?: {
          __typename?: "OutputSubscriptionRunSchedule"
          firstPickUp?: {
            __typename?: "OutputSubscriptionRunScheduleStop"
            dueTimeTLT?: string | null
            location?: {
              __typename?: "OutputSubscriptionRunScheduleStopLocation"
              city?: string | null
              state?: string | null
              zip?: string | null
            } | null
          } | null
        } | null
        latestDropoff?: {
          __typename?: "OutputSubscriptionRunSchedule"
          lastDropOff?: {
            __typename?: "OutputSubscriptionRunScheduleStop"
            dueTimeTLT?: string | null
            location?: {
              __typename?: "OutputSubscriptionRunScheduleStopLocation"
              city?: string | null
              state?: string | null
              zip?: string | null
            } | null
          } | null
        } | null
        payload?: {
          __typename?: "OutputSubscriptionRunPayloadType"
          routeName?: string | null
          startDate: any
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetProviderByIdQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  providerId: Scalars["ID"]["input"]
  startDate: Scalars["DateTime"]["input"]
  endDate: Scalars["DateTime"]["input"]
  sortBy?: InputMaybe<RunSortFieldsType>
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>
  maxItemCount?: InputMaybe<Scalars["Int"]["input"]>
  driverName?: InputMaybe<Scalars["String"]["input"]>
  driverIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >
  runStatus?: InputMaybe<
    Array<InputMaybe<RunStatusesType>> | InputMaybe<RunStatusesType>
  >
  bypassPagination?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type GetProviderByIdQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    runsV2?: {
      __typename?: "PaginatedRunV2Type"
      totalItemCount: number
      results?: Array<{
        __typename?: "RunV2Type"
        keyValue?: string | null
        stops: number
        passengers: number
        payload?: {
          __typename?: "OutputRunPayload"
          driverGUID?: string | null
          routeName?: string | null
          miles: any
          runState?: string | null
          driverInfo?: {
            __typename?: "OutputRunDriverInfo"
            driverName?: string | null
            driverPicURL?: string | null
            vehicleMake?: string | null
            vehicleModel?: string | null
            driverCode?: string | null
          } | null
        } | null
        firstPickup?: {
          __typename?: "OutputStop"
          dueDateTimeUTC: any
          dueTimeTLT: any
        } | null
        lastDropoff?: { __typename?: "OutputStop"; dueTimeTLT: any } | null
      } | null> | null
    } | null
  } | null
}

export type GetProviderNameQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
}>

export type GetProviderNameQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    payload?: {
      __typename?: "OutputProviderPayloadType"
      providerName?: string | null
    } | null
  } | null
}

export type GetRunByIdQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
  providerId: Scalars["ID"]["input"]
  runId: Scalars["Int"]["input"]
}>

export type GetRunByIdQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    runV2?: {
      __typename?: "RunV2Type"
      requirements?: Array<string | null> | null
      payload?: {
        __typename?: "OutputRunPayload"
        runState?: string | null
        routeName?: string | null
        driverGUID?: string | null
        miles: any
        districts?: Array<string | null> | null
        driverInfo?: {
          __typename?: "OutputRunDriverInfo"
          driverName?: string | null
          driverPicURL?: string | null
        } | null
        trips?: Array<{
          __typename?: "OutputTripInfo"
          tripGUID?: string | null
          memberGUID?: string | null
          requiresMonitor: boolean
          requirements?: Array<string | null> | null
          equipment?: Array<string | null> | null
          memberPII?: {
            __typename?: "OutputMemberPII"
            clientMemberCode?: string | null
            firstName?: string | null
            lastName?: string | null
          } | null
        } | null> | null
        stops?: Array<{
          __typename?: "OutputStop"
          order: number
          pUTripGUIDs?: Array<string | null> | null
          dOTripGUIDs?: Array<string | null> | null
          dueTimeTLT: any
          dueDateTLT: any
          location?: {
            __typename?: "OutputLocation"
            address1?: string | null
            city?: string | null
            state?: string | null
            zip?: string | null
          } | null
        } | null> | null
      } | null
      firstPickup?: {
        __typename?: "OutputStop"
        dueTimeTLT: any
        dueDateTimeUTC: any
        location?: {
          __typename?: "OutputLocation"
          address1?: string | null
          city?: string | null
          state?: string | null
          zip?: string | null
        } | null
      } | null
      lastDropoff?: {
        __typename?: "OutputStop"
        dueTimeTLT: any
        location?: {
          __typename?: "OutputLocation"
          address1?: string | null
          city?: string | null
          state?: string | null
          zip?: string | null
        } | null
      } | null
      equipment?: Array<{
        __typename?: "OutputEquipmentItem"
        name?: string | null
      } | null> | null
    } | null
  } | null
}

export type GetSalesforceUserInfoQueryVariables = Exact<{
  mrmProvider: Scalars["ID"]["input"]
}>

export type GetSalesforceUserInfoQuery = {
  __typename?: "RootQuery"
  provider?: {
    __typename?: "ProviderType"
    payload?: {
      __typename?: "OutputProviderPayloadType"
      providerName?: string | null
      externalId?: string | null
      externalSource?: string | null
    } | null
  } | null
}

export const AssignToOffersDocument = `
    mutation assignToOffers($driverId: ID!, $offerIds: [ID!]!) {
  assignToOffers(driverId: $driverId, offerIds: $offerIds) {
    isSuccess
    statusCode
    results {
      id
      success
    }
    status
    message
  }
}
    `

export const useAssignToOffersMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AssignToOffersMutation,
    TError,
    AssignToOffersMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignToOffersMutation,
    TError,
    AssignToOffersMutationVariables,
    TContext
  >(
    ["assignToOffers"],
    useCustomFetcher<AssignToOffersMutation, AssignToOffersMutationVariables>(
      AssignToOffersDocument
    ),
    options
  )
}

export const ChangeDriverDocument = `
    mutation changeDriver($driverId: ID!, $runIds: [ID!]!) {
  changeDriver(driverId: $driverId, runIds: $runIds)
}
    `

export const useChangeDriverMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ChangeDriverMutation,
    TError,
    ChangeDriverMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeDriverMutation,
    TError,
    ChangeDriverMutationVariables,
    TContext
  >(
    ["changeDriver"],
    useCustomFetcher<ChangeDriverMutation, ChangeDriverMutationVariables>(
      ChangeDriverDocument
    ),
    options
  )
}

export const ChangeOffersDriverDocument = `
    mutation changeOffersDriver($driverId: ID!, $offerIds: [ID!]!) {
  changeOffersDriver(driverId: $driverId, offerIds: $offerIds) {
    isSuccess
    statusCode
    results {
      id
      success
    }
    status
    message
  }
}
    `

export const useChangeOffersDriverMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ChangeOffersDriverMutation,
    TError,
    ChangeOffersDriverMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeOffersDriverMutation,
    TError,
    ChangeOffersDriverMutationVariables,
    TContext
  >(
    ["changeOffersDriver"],
    useCustomFetcher<
      ChangeOffersDriverMutation,
      ChangeOffersDriverMutationVariables
    >(ChangeOffersDriverDocument),
    options
  )
}

export const CreateUserDocument = `
    mutation createUser($mrmProvider: ID!, $user: UserInputType!) {
  createUser(mrmProvider: $mrmProvider, user: $user)
}
    `

export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >(
    ["createUser"],
    useCustomFetcher<CreateUserMutation, CreateUserMutationVariables>(
      CreateUserDocument
    ),
    options
  )
}

export const DownloadDriverDetailsDocument = `
    query downloadDriverDetails($mrmProvider: ID!, $driverId: ID!, $sortBy: RunSortFieldsType, $sortAsc: Boolean, $startDate: DateTime!, $endDate: DateTime!, $runStatus: [RunStatusesType]) {
  driver(mrmProvider: $mrmProvider, driverId: $driverId) {
    downloadRunsV2(
      sortBy: $sortBy
      sortAsc: $sortAsc
      startDate: $startDate
      endDate: $endDate
      runStatus: $runStatus
    ) {
      data
    }
  }
}
    `

export const useDownloadDriverDetailsQuery = <
  TData = DownloadDriverDetailsQuery,
  TError = unknown
>(
  variables: DownloadDriverDetailsQueryVariables,
  options?: UseQueryOptions<DownloadDriverDetailsQuery, TError, TData>
) => {
  return useQuery<DownloadDriverDetailsQuery, TError, TData>(
    ["downloadDriverDetails", variables],
    useCustomFetcher<
      DownloadDriverDetailsQuery,
      DownloadDriverDetailsQueryVariables
    >(DownloadDriverDetailsDocument).bind(null, variables),
    options
  )
}

export const DownloadDriverRunsDocument = `
    query downloadDriverRuns($mrmProvider: ID!, $driverId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
  driver(mrmProvider: $mrmProvider, driverId: $driverId) {
    downloadRuns(startDate: $startDate, endDate: $endDate) {
      filename
      data
    }
  }
}
    `

export const useDownloadDriverRunsQuery = <
  TData = DownloadDriverRunsQuery,
  TError = unknown
>(
  variables: DownloadDriverRunsQueryVariables,
  options?: UseQueryOptions<DownloadDriverRunsQuery, TError, TData>
) => {
  return useQuery<DownloadDriverRunsQuery, TError, TData>(
    ["downloadDriverRuns", variables],
    useCustomFetcher<DownloadDriverRunsQuery, DownloadDriverRunsQueryVariables>(
      DownloadDriverRunsDocument
    ).bind(null, variables),
    options
  )
}

export const DownloadProviderRunsDocument = `
    query downloadProviderRuns($mrmProvider: ID!, $startDate: DateTime!, $endDate: DateTime!, $sortBy: RunSortFieldsType, $sortAsc: Boolean, $driverName: String, $driverIds: [ID], $runStatus: [RunStatusesType]) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    downloadRuns(
      startDate: $startDate
      endDate: $endDate
      sortBy: $sortBy
      sortAsc: $sortAsc
      search: $driverName
      driverIds: $driverIds
      runStatus: $runStatus
    ) {
      filename
      data
    }
  }
}
    `

export const useDownloadProviderRunsQuery = <
  TData = DownloadProviderRunsQuery,
  TError = unknown
>(
  variables: DownloadProviderRunsQueryVariables,
  options?: UseQueryOptions<DownloadProviderRunsQuery, TError, TData>
) => {
  return useQuery<DownloadProviderRunsQuery, TError, TData>(
    ["downloadProviderRuns", variables],
    useCustomFetcher<
      DownloadProviderRunsQuery,
      DownloadProviderRunsQueryVariables
    >(DownloadProviderRunsDocument).bind(null, variables),
    options
  )
}

export const GetAcceptedOffersDocument = `
    query getAcceptedOffers($mrmProvider: ID!, $search: String, $pickup: [OfferPickupsType], $sortBy: OfferSortFieldsType, $sortAsc: Boolean, $bypassPagination: Boolean, $pageNumber: Int, $maxItemCount: Int) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    acceptedOffers(
      search: $search
      pickup: $pickup
      sortBy: $sortBy
      sortAsc: $sortAsc
      bypassPagination: $bypassPagination
      pageNumber: $pageNumber
      maxItemCount: $maxItemCount
    ) {
      count
      totalItemCount
      endOfList
      results {
        keyValue
        earliestPickup {
          firstPickUp {
            dueTimeTLT
          }
        }
        latestDropoff {
          lastDropOff {
            dueTimeTLT
          }
        }
        students
        payload {
          routeName
          startDate
          driverGUID
          driverInfo {
            driverName
          }
        }
      }
    }
  }
}
    `

export const useGetAcceptedOffersQuery = <
  TData = GetAcceptedOffersQuery,
  TError = unknown
>(
  variables: GetAcceptedOffersQueryVariables,
  options?: UseQueryOptions<GetAcceptedOffersQuery, TError, TData>
) => {
  return useQuery<GetAcceptedOffersQuery, TError, TData>(
    ["getAcceptedOffers", variables],
    useCustomFetcher<GetAcceptedOffersQuery, GetAcceptedOffersQueryVariables>(
      GetAcceptedOffersDocument
    ).bind(null, variables),
    options
  )
}

export const GetDriverByIdDocument = `
    query getDriverById($mrmProvider: ID!, $driverId: ID!) {
  driver(mrmProvider: $mrmProvider, driverId: $driverId) {
    payload {
      firstName
      lastName
      mDDCode
      picURL
    }
  }
}
    `

export const useGetDriverByIdQuery = <
  TData = GetDriverByIdQuery,
  TError = unknown
>(
  variables: GetDriverByIdQueryVariables,
  options?: UseQueryOptions<GetDriverByIdQuery, TError, TData>
) => {
  return useQuery<GetDriverByIdQuery, TError, TData>(
    ["getDriverById", variables],
    useCustomFetcher<GetDriverByIdQuery, GetDriverByIdQueryVariables>(
      GetDriverByIdDocument
    ).bind(null, variables),
    options
  )
}

export const GetDriverRunsByIdDocument = `
    query getDriverRunsById($mrmProvider: ID!, $driverId: ID!, $sortBy: RunSortFieldsType, $sortAsc: Boolean, $startDate: DateTime!, $endDate: DateTime!, $pageNumber: Int, $maxItemCount: Int, $runStatus: [RunStatusesType], $bypassPagination: Boolean) {
  driver(mrmProvider: $mrmProvider, driverId: $driverId) {
    runsV2(
      sortBy: $sortBy
      sortAsc: $sortAsc
      startDate: $startDate
      endDate: $endDate
      pageNumber: $pageNumber
      maxItemCount: $maxItemCount
      runStatus: $runStatus
      bypassPagination: $bypassPagination
    ) {
      totalItemCount
      results {
        keyValue
        firstPickup {
          dueTimeTLT
          dueDateTimeUTC
          location {
            address1
          }
        }
        lastDropoff {
          dueTimeTLT
          location {
            address1
          }
        }
        passengers
        stops
        payload {
          runState
        }
      }
    }
  }
}
    `

export const useGetDriverRunsByIdQuery = <
  TData = GetDriverRunsByIdQuery,
  TError = unknown
>(
  variables: GetDriverRunsByIdQueryVariables,
  options?: UseQueryOptions<GetDriverRunsByIdQuery, TError, TData>
) => {
  return useQuery<GetDriverRunsByIdQuery, TError, TData>(
    ["getDriverRunsById", variables],
    useCustomFetcher<GetDriverRunsByIdQuery, GetDriverRunsByIdQueryVariables>(
      GetDriverRunsByIdDocument
    ).bind(null, variables),
    options
  )
}

export const GetDriversDocument = `
    query getDrivers($mrmProvider: ID!, $search: String, $sortBy: DriverSortFieldsType, $sortAsc: Boolean, $bypassPagination: Boolean, $pageNumber: Int, $maxItemCount: Int, $filter: InputDriverFilterType) {
  drivers(
    mrmProvider: $mrmProvider
    search: $search
    sortBy: $sortBy
    sortAsc: $sortAsc
    bypassPagination: $bypassPagination
    pageNumber: $pageNumber
    maxItemCount: $maxItemCount
    filter: $filter
  ) {
    results {
      keyValue
      payload {
        firstName
        lastName
        vehicleInfo {
          make
          model
          color
        }
        eligibleFlag
        driverCode
        mDDCode
      }
    }
    count
    totalItemCount
    endOfList
  }
}
    `

export const useGetDriversQuery = <TData = GetDriversQuery, TError = unknown>(
  variables: GetDriversQueryVariables,
  options?: UseQueryOptions<GetDriversQuery, TError, TData>
) => {
  return useQuery<GetDriversQuery, TError, TData>(
    ["getDrivers", variables],
    useCustomFetcher<GetDriversQuery, GetDriversQueryVariables>(
      GetDriversDocument
    ).bind(null, variables),
    options
  )
}

export const GetDriversIdsDocument = `
    query getDriversIds($mrmProvider: ID!, $search: String, $sortBy: DriverSortFieldsType, $sortAsc: Boolean, $bypassPagination: Boolean, $pageNumber: Int, $maxItemCount: Int, $filter: InputDriverFilterType) {
  drivers(
    mrmProvider: $mrmProvider
    search: $search
    sortBy: $sortBy
    sortAsc: $sortAsc
    bypassPagination: $bypassPagination
    pageNumber: $pageNumber
    maxItemCount: $maxItemCount
    filter: $filter
  ) {
    results {
      keyValue
    }
  }
}
    `

export const useGetDriversIdsQuery = <
  TData = GetDriversIdsQuery,
  TError = unknown
>(
  variables: GetDriversIdsQueryVariables,
  options?: UseQueryOptions<GetDriversIdsQuery, TError, TData>
) => {
  return useQuery<GetDriversIdsQuery, TError, TData>(
    ["getDriversIds", variables],
    useCustomFetcher<GetDriversIdsQuery, GetDriversIdsQueryVariables>(
      GetDriversIdsDocument
    ).bind(null, variables),
    options
  )
}

export const GetDriversNamesIDsDocument = `
    query getDriversNamesIDs($mrmProvider: ID!, $bypassPagination: Boolean, $filter: InputDriverFilterType) {
  drivers(
    mrmProvider: $mrmProvider
    bypassPagination: $bypassPagination
    filter: $filter
  ) {
    results {
      keyValue
      payload {
        firstName
        lastName
        driverCode
      }
    }
    totalItemCount
  }
}
    `

export const useGetDriversNamesIDsQuery = <
  TData = GetDriversNamesIDsQuery,
  TError = unknown
>(
  variables: GetDriversNamesIDsQueryVariables,
  options?: UseQueryOptions<GetDriversNamesIDsQuery, TError, TData>
) => {
  return useQuery<GetDriversNamesIDsQuery, TError, TData>(
    ["getDriversNamesIDs", variables],
    useCustomFetcher<GetDriversNamesIDsQuery, GetDriversNamesIDsQueryVariables>(
      GetDriversNamesIDsDocument
    ).bind(null, variables),
    options
  )
}

export const GetOfferDocument = `
    query getOffer($mrmProvider: ID!, $subscriptionRunId: ID!) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    offer(subscriptionRunId: $subscriptionRunId) {
      offer {
        offerStatus
      }
      tripDays
      earliestPickup {
        firstPickUp {
          dueTimeTLT
        }
      }
      latestDropoff {
        lastDropOff {
          dueTimeTLT
        }
      }
      payload {
        routeName
        driverGUID
        districts
        driverInfo {
          driverName
        }
        startDate
        endDate
        schedules {
          estimatedMiles
          estimatedPayAmount
          stops {
            order
            dueTimeTLT
            location {
              address1
              city
              state
              zip
            }
            puSubscriptionTripGUIDs
            doSubscriptionTripGUIDs
          }
          firstPickUp {
            puSubscriptionTripGUIDs
            doSubscriptionTripGUIDs
          }
        }
        subscriptionTrips {
          subscriptionTripGUID
          memberGUID
          requiresMonitor
          memberPII {
            firstName
            lastName
          }
          requiresMonitor
          requirements
          equipment
        }
      }
    }
  }
}
    `

export const useGetOfferQuery = <TData = GetOfferQuery, TError = unknown>(
  variables: GetOfferQueryVariables,
  options?: UseQueryOptions<GetOfferQuery, TError, TData>
) => {
  return useQuery<GetOfferQuery, TError, TData>(
    ["getOffer", variables],
    useCustomFetcher<GetOfferQuery, GetOfferQueryVariables>(
      GetOfferDocument
    ).bind(null, variables),
    options
  )
}

export const GetOpenOffersDocument = `
    query getOpenOffers($mrmProvider: ID!, $search: String, $pickup: [OfferPickupsType], $sortBy: OfferSortFieldsType, $sortAsc: Boolean, $bypassPagination: Boolean, $pageNumber: Int, $maxItemCount: Int) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    openOffers(
      search: $search
      pickup: $pickup
      sortBy: $sortBy
      sortAsc: $sortAsc
      bypassPagination: $bypassPagination
      pageNumber: $pageNumber
      maxItemCount: $maxItemCount
    ) {
      count
      totalItemCount
      endOfList
      results {
        keyValue
        earliestPickup {
          firstPickUp {
            dueTimeTLT
            location {
              city
              state
              zip
            }
          }
        }
        latestDropoff {
          lastDropOff {
            dueTimeTLT
            location {
              city
              state
              zip
            }
          }
        }
        students
        tripDays
        payload {
          routeName
          startDate
        }
      }
    }
  }
}
    `

export const useGetOpenOffersQuery = <
  TData = GetOpenOffersQuery,
  TError = unknown
>(
  variables: GetOpenOffersQueryVariables,
  options?: UseQueryOptions<GetOpenOffersQuery, TError, TData>
) => {
  return useQuery<GetOpenOffersQuery, TError, TData>(
    ["getOpenOffers", variables],
    useCustomFetcher<GetOpenOffersQuery, GetOpenOffersQueryVariables>(
      GetOpenOffersDocument
    ).bind(null, variables),
    options
  )
}

export const GetProviderByIdDocument = `
    query getProviderById($mrmProvider: ID!, $providerId: ID!, $startDate: DateTime!, $endDate: DateTime!, $sortBy: RunSortFieldsType, $sortAsc: Boolean, $pageNumber: Int, $maxItemCount: Int, $driverName: String, $driverIds: [ID], $runStatus: [RunStatusesType], $bypassPagination: Boolean) {
  provider(mrmProvider: $mrmProvider, providerId: $providerId) {
    runsV2(
      startDate: $startDate
      endDate: $endDate
      pageNumber: $pageNumber
      maxItemCount: $maxItemCount
      sortBy: $sortBy
      sortAsc: $sortAsc
      search: $driverName
      driverIds: $driverIds
      runStatus: $runStatus
      bypassPagination: $bypassPagination
    ) {
      totalItemCount
      results {
        keyValue
        payload {
          driverGUID
          driverInfo {
            driverName
            driverPicURL
            vehicleMake
            vehicleModel
            driverCode
          }
          routeName
          miles
          runState
        }
        stops
        passengers
        firstPickup {
          dueDateTimeUTC
          dueTimeTLT
        }
        lastDropoff {
          dueTimeTLT
        }
      }
    }
  }
}
    `

export const useGetProviderByIdQuery = <
  TData = GetProviderByIdQuery,
  TError = unknown
>(
  variables: GetProviderByIdQueryVariables,
  options?: UseQueryOptions<GetProviderByIdQuery, TError, TData>
) => {
  return useQuery<GetProviderByIdQuery, TError, TData>(
    ["getProviderById", variables],
    useCustomFetcher<GetProviderByIdQuery, GetProviderByIdQueryVariables>(
      GetProviderByIdDocument
    ).bind(null, variables),
    options
  )
}

export const GetProviderNameDocument = `
    query getProviderName($mrmProvider: ID!) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    payload {
      providerName
    }
  }
}
    `

export const useGetProviderNameQuery = <
  TData = GetProviderNameQuery,
  TError = unknown
>(
  variables: GetProviderNameQueryVariables,
  options?: UseQueryOptions<GetProviderNameQuery, TError, TData>
) => {
  return useQuery<GetProviderNameQuery, TError, TData>(
    ["getProviderName", variables],
    useCustomFetcher<GetProviderNameQuery, GetProviderNameQueryVariables>(
      GetProviderNameDocument
    ).bind(null, variables),
    options
  )
}

export const GetRunByIdDocument = `
    query getRunById($mrmProvider: ID!, $providerId: ID!, $runId: Int!) {
  provider(mrmProvider: $mrmProvider, providerId: $providerId) {
    runV2(runId: $runId) {
      payload {
        runState
        routeName
        driverGUID
        miles
        driverInfo {
          driverName
          driverPicURL
        }
        districts
        trips {
          tripGUID
          memberGUID
          memberPII {
            clientMemberCode
            firstName
            lastName
          }
          requiresMonitor
          requirements
          equipment
        }
        stops {
          order
          pUTripGUIDs
          dOTripGUIDs
          dueTimeTLT
          dueDateTLT
          location {
            address1
            city
            state
            zip
          }
        }
      }
      firstPickup {
        dueTimeTLT
        dueDateTimeUTC
        location {
          address1
          city
          state
          zip
        }
      }
      lastDropoff {
        dueTimeTLT
        location {
          address1
          city
          state
          zip
        }
      }
      equipment {
        name
      }
      requirements
    }
  }
}
    `

export const useGetRunByIdQuery = <TData = GetRunByIdQuery, TError = unknown>(
  variables: GetRunByIdQueryVariables,
  options?: UseQueryOptions<GetRunByIdQuery, TError, TData>
) => {
  return useQuery<GetRunByIdQuery, TError, TData>(
    ["getRunById", variables],
    useCustomFetcher<GetRunByIdQuery, GetRunByIdQueryVariables>(
      GetRunByIdDocument
    ).bind(null, variables),
    options
  )
}

export const GetSalesforceUserInfoDocument = `
    query getSalesforceUserInfo($mrmProvider: ID!) {
  provider(mrmProvider: $mrmProvider, providerId: $mrmProvider) {
    payload {
      providerName
      externalId
      externalSource
    }
  }
}
    `

export const useGetSalesforceUserInfoQuery = <
  TData = GetSalesforceUserInfoQuery,
  TError = unknown
>(
  variables: GetSalesforceUserInfoQueryVariables,
  options?: UseQueryOptions<GetSalesforceUserInfoQuery, TError, TData>
) => {
  return useQuery<GetSalesforceUserInfoQuery, TError, TData>(
    ["getSalesforceUserInfo", variables],
    useCustomFetcher<
      GetSalesforceUserInfoQuery,
      GetSalesforceUserInfoQueryVariables
    >(GetSalesforceUserInfoDocument).bind(null, variables),
    options
  )
}
