import { useGetSalesforceUserInfoQuery } from "generated/graphql"
import salesforceUserInfoAdapter from "graphql/adapters/SalesforceUserLayout.adapter"
import useProviderVars from "graphql/defaultVariables/ProviderVariables"
import { FC } from "react"
import { Outlet } from "react-router-dom"
import { SALESFORCE } from "utils/constants"

const SalesforceUserLayout: FC = () => {
  const vars = useProviderVars()
  const providerAPI = useGetSalesforceUserInfoQuery({
    ...vars
  })

  const { providerName, externalId, externalSource } =
    salesforceUserInfoAdapter({
      dataAPI: providerAPI.data
    })

  const mustCompleteProfile =
    providerAPI.data === undefined
      ? false
      : externalSource?.toLowerCase() === SALESFORCE &&
        !!externalId &&
        !providerName

  const salesforceSite = `${process.env.REACT_APP_COMPLIANCE_BASE_URL}/${process.env.REACT_APP_COMPLIANCE_ROUTE}/${process.env.REACT_APP_COMPLIANCE_ROUTE_ACCOUNT}/${externalId}?auth=${process.env.REACT_APP_COMPLIANCE_AUTH_SUFFIX}`

  if (mustCompleteProfile) window.location.href = salesforceSite

  return <Outlet />
}

export default SalesforceUserLayout
