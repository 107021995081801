import { Box, styled } from "@mui/material"
import { DRAWER_WIDTH, FOOTER_HEIGHT, HEADER_HEIGHT } from "utils/constants"

import { IBody } from "./Body.types"

const Body = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open"
})<IBody>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  alignItems: "center",
  flex: 1,
  height: `calc(100% - calc(${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px))`,
  marginTop: `${HEADER_HEIGHT}px`,
  marginBottom: `${FOOTER_HEIGHT}px`,
  overflowY: "auto",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: open ? DRAWER_WIDTH : theme.spacing(0),
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(0)
  }
}))

export const StyledViewContent = styled(Box)(() => ({
  width: "100%"
}))

export default Body
