export interface IContextProvider {
  children?: React.ReactNode
}

// User Types

export interface IUserState {
  userFirstName: string
  loginToken: string
  providerId: string
  appLanguage: string
  userUniqueId: string
  userIsAdmin: boolean
  salesforceAuthenticated: boolean
}

export interface IUserAction {
  type: keyof typeof USER_ACTIONS
  payload: string | boolean
}

export const USER_ACTIONS = {
  SET_USER_FIRST_NAME: "SET_USER_FIRST_NAME",
  SET_TOKEN: "SET_TOKEN",
  SET_PROVIDER_ID: "SET_PROVIDER_ID",
  SET_APP_LANGUAGE: "SET_APP_LANGUAGE",
  SET_USER_UNIQUE_ID: "SET_USER_UNIQUE_ID",
  SET_USER_IS_ADMIN: "SET_USER_IS_ADMIN",
  SET_SALESFORCE_AUTHENTICATED: "SET_SALESFORCE_AUTHENTICATED"
} as const
