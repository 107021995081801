import { createContext, FC, useContext, useReducer } from "react"
import {
  INITIAL_PAGE_SETTINGS,
  PageLocalStorageType
} from "utils/constants/localStorage.constants"

import {
  IPageLocalStorageAction,
  IPageLocalStorageContextProvider
} from "./PageLocalStorageContext.types"
import pageLocalStorageReducer, {
  initialPageLocalStorageState
} from "./reducers/pageLocalStorageReducer"

export const DispatchContext = createContext<
  React.Dispatch<IPageLocalStorageAction>
>(() => {})
export const StateContext = createContext<PageLocalStorageType>(
  initialPageLocalStorageState
)

const getStorageValue = <T,>(key: string, defaultValue: T) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key)
    const initial = saved !== null ? JSON.parse(saved) : defaultValue
    if (saved === null) localStorage.setItem(key, JSON.stringify(defaultValue))

    return initial as T
  }

  localStorage.setItem(key, JSON.stringify(defaultValue))
  return defaultValue
}

export const PageLocalStorageContextProvider: FC<
  IPageLocalStorageContextProvider
> = ({ children = [], userId = "" }) => {
  const currentLocalStorageValue = getStorageValue(
    userId,
    INITIAL_PAGE_SETTINGS
  )
  const [state, dispatch] = useReducer(
    pageLocalStorageReducer,
    currentLocalStorageValue
  )

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export const useContextDispatch = () => useContext(DispatchContext)
export const useStateDispatch = () => useContext(StateContext)
