import { QueryCache, QueryClient } from "@tanstack/react-query"
import log from "loglevel"
import MINUTES_ON_MILLISECONDS from "utils/constants/date.constants"

const client = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) =>
      log.error(`Something went wrong: ${(error as Error)?.message}`)
  }),
  defaultOptions: {
    queries: {
      staleTime: MINUTES_ON_MILLISECONDS
    }
  }
})

export default client
