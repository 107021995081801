import log from "loglevel"
import { FC, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import useUserContext from "../hooks/useUserContext"
import Auth from "./Auth"

const RequireAuth: FC = () => {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const {
    setUserFirstName,
    setLoginToken,
    setProviderId,
    setUserUniqueId,
    setUserIsAdmin,
    setSalesforceAuthenticated
  } = useUserContext()
  const location = useLocation()
  const navigate = useNavigate()

  const IsAuth = async (): Promise<boolean> => {
    const pathName = location.pathname
    const isRedirectPage = pathName.toLowerCase().indexOf("redirect") > -1
    let redirectTo: string

    if (!isRedirectPage) {
      redirectTo = pathName
      log.info(`loading page with redirect of ${redirectTo}`)
    }

    return Auth.login(() => {
      log.debug("We have logged in")
      if (isRedirectPage && redirectTo) {
        log.info(`We are redirecting to ${redirectTo}`)
        navigate(redirectTo, { replace: true })
      } else {
        log.debug("Rendering normally")
      }
    })
  }

  useEffect(() => {
    async function getAuthorization() {
      const isAllowed = await IsAuth()
      setIsAuthorized(isAllowed)
      setUserFirstName(Auth.getUserFirstName())
      setLoginToken(Auth.getToken())
      setProviderId(Auth.getProviderId())
      setUserUniqueId(Auth.getUserUniqueId())
      setUserIsAdmin(Auth.getUserIsAdmin())
      setSalesforceAuthenticated(!!Auth.getExternalContactId())
    }
    getAuthorization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isAuthorized ? <Outlet /> : <div />
}

export default RequireAuth
