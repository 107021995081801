/* eslint-disable import/prefer-default-export */
export const PAGE_STYLES = `
    @page {
        size: A4;
    }

    @media all {
        #print-section {
            width: 1024px;
            margin-left: 32px;
        }

        #print-hide {
            display: none;
        }
    }
      
    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }

        
    }
`
