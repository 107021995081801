import { OpenInNew as OpenInNewIcon } from "@mui/icons-material"
import { Box, Link, Select } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Link as NavLink } from "react-router-dom"

export const Root = styled(Box)({
  marginLeft: "auto"
})

export const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  fontSize: "1rem",
  bottom: -3,
  position: "relative",
  marginLeft: theme.spacing(1)
}))

export const RightBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    display: "none"
  }
}))

export const RightBoxLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),

  "&:hover": {
    color: theme.palette.common.white
  },
  "&:last-child": {
    marginRight: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  textTransform: "capitalize",
  color: `${theme.palette.common.white}9`,
  ".MuiOutlinedInput-notchedOutline": {
    border: "0px solid transparent"
  },
  ".MuiSvgIcon-root": {
    color: `${theme.palette.common.white}9`
  }
}))

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: `${theme.palette.common.white}9`,
  textDecoration: "none",
  marginRight: theme.spacing(2),
  lineHeight: "105%",

  "&:hover": {
    color: theme.palette.common.white
  }
}))
