import {
  IPageLocalStorageAction,
  PAGE_LOCAL_STORAGE_ACTIONS
} from "contexts/PageLocalStorageContext/PageLocalStorageContext.types"
import {
  INITIAL_PAGE_SETTINGS,
  PageLocalStorageType
} from "utils/constants/localStorage.constants"

export const initialPageLocalStorageState: PageLocalStorageType =
  INITIAL_PAGE_SETTINGS

const pageLocalStorageReducer = (
  state: PageLocalStorageType,
  action: IPageLocalStorageAction
): PageLocalStorageType => {
  if (action.type === PAGE_LOCAL_STORAGE_ACTIONS.SET_PAGE_LOCAL_STORAGE) {
    return {
      ...state,
      ...action.payload
    }
  }

  return state
}

export default pageLocalStorageReducer
