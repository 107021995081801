import environment from "../../environment"

export const APP = {
  VERSION: "1.0.0-beta.5-SP",
  NO_VERSION: "",
  FIRST_LOGIN: false
}

// Accessibility
export const TAB_INDEX = -1

export const MIN_LENGTH_INPUT = 2

export const DRAWER_WIDTH = 260
export const HEADER_HEIGHT = 64
export const FOOTER_HEIGHT = 0

export const NO_STOPS = 0
export const NO_PICK_UPS = 0
export const NO_DROP_OFFS = 0
export const NO_COST = 0

export const EMPTY_ARRAY_LENGTH = 1

export const INITIAL_ORDER = "asc"

export const ROUTES = {
  INDEX: "schedule",
  SCHEDULE: "/schedule",
  DRIVERS: "/drivers",
  VEHICLE_RUN: "/vehicleRun",
  USERS: "/users",
  NOTIFICATIONS: "/notifications",
  ASSIGN_DRIVER: "/assignDriver",
  TRIP_OFFERS_OPS: "/tripOffersOPS",
  OFFERS_DETAILS_SP: "/offerDetailsSP",
  OFFERS_DETAILS_OPS: "/offerDetailsOPS",
  TRIP_OFFERS_SP: "/tripOffersSP",
  RESOURCES: "/resources",
  404: "/404"
} as const

export const SALESFORCE = "salesforce"

// Support
export const TECH_SUPPORT_PAGE =
  "https://rrts1614707543.atlassian.net/servicedesk/customer/portal/3/group/13"

// languages
export const ENGLISH_POSITION = 0
export const SPANISH_POSITION = 1

export const languages = [
  {
    code: "en-US",
    name: "English",
    locateDate: `en-US`,
    isoCode: "en"
  },
  {
    code: "es-ES",
    name: "Español",
    locateDate: `es-ES`,
    isoCode: "es"
  }
]

export const initialLanguage = languages[ENGLISH_POSITION].code

// dates
export const DAYS_AHEAD = 4
export const MONTH_CORRECTION = 1

// hours
export const MINUTES_AHEAD = 20

// table
export const ROWS_PAGE_RANGES = [5, 10, 25, 50, 100]
export const ROWS_PAGE_DEFAULT = 100
export const INITIAL_PAGE = 0
export const TOTAL_PAGES_DEFAULT = 1

// GraphQL Queries
export const gqlQueries = {
  // Queries
  getDrivers: "getDrivers",
  getDriverById: "getDriverById",
  getDriverRunsById: "getDriverRunsById",
  getRunById: "getRunById",
  getProviderById: "getProviderById",
  getOpenOffers: "getOpenOffers",
  getAcceptedOffers: "getAcceptedOffers",
  getOffer: "getOffer",
  getProviderName: "getProviderName",
  getSalesforceUserInfo: "getSalesforceUserInfo",
  getDriversId: "getDriversId",
  getDriversNamesIDs: "getDriversNamesIDs",
  // Mutations
  createUser: "createUser",
  assignToOffers: "assignToOffers",
  changeOffersDriver: "changeOffersDriver",
  changeDriver: "changeDriver"
} as const

// Skeleton
export const TITLE_HEIGHT = 40
export const TEXT_HEIGHT = 16
export const CARD_HEIGHT = 200
export const SKELETON_WIDTH = 200
export const SKELETON_SMALL_WIDTH = 100
export const SKELETON_X_SMALL_WIDTH = 75

// ENV
export const IS_DEV_ENVIRONMENT = ["local", "dev"].some(
  (element) => element === environment
)
export const IS_BROADCAST = process.env.REACT_APP_IS_BROADCAST === "true"
export const IS_OPS_USER = process.env.REACT_APP_IS_OPS_USER === "true"
export const IS_PROD_ENVIRONMENT = environment === "prod"

// HTTP response codes
export const HttpCodes = {
  unAuthorized: 401,
  forbidden: 403
}

// Date
export const DATE_AM_PM_FORMAT = 12
export const ADD_ZERO_BEFORE = 10
export const ZERO_HOURS = 0

// query Params
export const QUERY_PARAMS = {
  SEARCH: "search",
  RANGE_DATE: "date",
  PAGE_NUMBER: "page",
  ORDER_BY: "orderBy",
  ORDER: "order",
  TAB: "tab"
}

export const INITIAL_SEARCH_VALUE = ""

const startDate = new Date()

export const INITIAL_DATE_RANGE = {
  from: {
    day: startDate.getDate(),
    month: startDate.getMonth() + MONTH_CORRECTION,
    year: startDate.getFullYear()
  },
  to: {
    day: startDate.getDate(),
    month: startDate.getMonth() + MONTH_CORRECTION,
    year: startDate.getFullYear()
  }
}

export const INITIAL_DATE_PLUS_FOUR = {
  from: {
    day: startDate.getDate(),
    month: startDate.getMonth() + MONTH_CORRECTION,
    year: startDate.getFullYear()
  },
  to: {
    day: startDate.getDate() + 4,
    month: startDate.getMonth() + MONTH_CORRECTION,
    year: startDate.getFullYear()
  }
}

export const INITIAL_MAX_DATE = {
  day: startDate.getDate() + DAYS_AHEAD,
  month: startDate.getMonth() + MONTH_CORRECTION,
  year: startDate.getFullYear()
}

export const SNACKBAR_DURATION = 3000

export const EMPTY_GA = "G-XXXXXXXXXX"
export const EMPTY_CLARITY = ""

export const INITIAL_TAB = "0"
