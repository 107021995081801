/* eslint-disable max-lines */

import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest
} from "@azure/msal-browser"
import log from "loglevel"

let tokenKey: string
let userFirstNameKey: string
let userProviderIdKey: string
let userIsAdmin: boolean
let userUniqueId: string
let extensionExternalContactId: string

const LOGINREDIRECTURI = `${window.location.origin}/redirect`

interface CustomToken extends AuthenticationResult {
  idTokenClaims: {
    extension_ExternalContactId: string
    given_name: string
    extension_IsAdministrator: boolean
    extension_ProviderCode: string
  }
}

const loggerCallback = (logLevel: LogLevel, message: string): void => {
  log.debug(`Auth: [${logLevel.toString}]: ${message}`)
}

const authority = `https://${process.env.REACT_APP_AUTH_AUTHORITYSUB}.b2clogin.com/${process.env.REACT_APP_AUTH_AUTHORITYSUB}.onmicrosoft.com/${process.env.REACT_APP_AUTH_SIGNINFLOW}`

const msalApp = new PublicClientApplication({
  auth: {
    clientId: `${process.env.REACT_APP_AUTH_CLIENTID}`,
    authority,
    redirectUri: LOGINREDIRECTURI,
    knownAuthorities: [authority],
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
      loggerCallback,
      correlationId: "1234", // This is set to get around a bug in the framework where if you don't set it,
      // no authentication can occur, don't believe me? comment it out :)
      logLevel: LogLevel.Info,
      piiLoggingEnabled: true
    }
  }
})

const req: RedirectRequest = {
  scopes: [process.env.REACT_APP_AUTH_SCOPE ?? ""]
}

const errorHandler = async (error: Error, fnlogout: () => Promise<void>) => {
  if (error.message === "User login is required.") {
    log.debug(error.message)
    await fnlogout()
    return
  }

  if (error.message.includes("User does not have an existing session")) {
    log.warn("Session Expired, sending to login page")
    await fnlogout()
    // Should be 'msalApp.logout();', if cookies are not blocked... but we can't tell.
    return
  }

  if (error.message.includes("Token calls are blocked in hidden iframes")) {
    // This is a 'feature' of the MSAL library and we can safely ignore it
    // NOOP
    return
  }

  if (
    error.message.includes(
      "monitor_window_timeout: Token acquisition in iframe failed due to timeout."
    )
  ) {
    log.info("Time Out - long time without interaction")
    await fnlogout()
  }

  // Sometimes with login issues it happens before the logging has been init
  log.debug("[MSAL GetToken]ERROR: ", error)
  await fnlogout()
}

const Auth = {
  login: async (success: () => void): Promise<boolean> => {
    try {
      const tokenResponse =
        (await msalApp.handleRedirectPromise()) as CustomToken
      let account: AccountInfo | null

      if (tokenResponse) {
        account = tokenResponse.account
      } else {
        ;[account] = msalApp.getAllAccounts()
      }

      if (account != null && tokenResponse != null) {
        if (
          account?.idTokenClaims?.tfp === process.env.REACT_APP_AUTH_RESETFLOW
        ) {
          log.debug(
            "[ResetPassword] Force logout after a successful reset password flow"
          )
          await msalApp.logoutRedirect()
        }
        await Auth.postLogin(tokenResponse)
        success()
        return true
      }

      if (account != null) {
        if (
          account?.idTokenClaims?.tfp === process.env.REACT_APP_AUTH_RESETFLOW
        ) {
          log.debug(
            "[ResetPassword] Force logout after a successful reset password flow"
          )
          await msalApp.logoutRedirect()
        }
        await Auth.tokenRenewal(success)
        return true
      }

      log.warn(
        "[login] No account or tokenResponse present. User must now login."
      )
      await msalApp.loginRedirect(req)
      return true
    } catch (error) {
      if (!(error instanceof Error)) {
        log.debug("Unexpected error", error)
        return false
      }

      if (error.message.includes("AADB2C90118")) {
        log.debug("I am redirecting cause password reset")
        await Auth.resetPassword()
        return true
      }

      if (error.message.includes("AADB2C90091")) {
        log.warn(
          "User cancelled a password reset, but msal has a bug so were sending them to the homescreen"
        )
        await msalApp.loginRedirect(req)
        return true
      }

      if (error.message.includes("AADB2C90182")) {
        log.warn(
          "There are 2 tabs/windows with the page login opened, This is not allowed by msal, close one"
        )
        await msalApp.loginRedirect(req)
        return true
      }

      if (error.message.includes("interaction_in_progress")) {
        log.warn("Interaction in progress error")
        await Auth.removeToken()
        log.debug("hard deleting the msal cookie")
        const cookieName = " msal.interaction.status"
        if (document.cookie.includes(cookieName)) {
          log.debug("deleting cookie", document.cookie)
          document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
        }
        document.location = "/"
        return true
      }

      log.warn("[login] Failed to handleRedirectPromise()", error)
      return false
    }
  },

  removeToken: async () => {
    tokenKey = ""
    userFirstNameKey = ""
    userProviderIdKey = ""
    userUniqueId = ""
    userIsAdmin = false
    extensionExternalContactId = ""
  },

  logoutAfterError: async () => {
    await msalApp.logoutRedirect()
    await Auth.tokenRenewal()
  },

  tokenRenewal: async (success?: () => void) => {
    let tokenRenewal: CustomToken | void
    try {
      log.info("[login] Trying to renew token.")
      tokenRenewal = (await msalApp.acquireTokenSilent({
        account: msalApp.getAllAccounts()[0],
        scopes: req.scopes
      })) as CustomToken
      await Auth.postLogin(tokenRenewal)
      if (success != null) success()
      log.info("[login] Token renewed.")
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        tokenRenewal = await msalApp.acquireTokenRedirect(req)
        if (tokenRenewal !== undefined) {
          await Auth.postLogin(tokenRenewal)
        }
        if (success != null) success()
        return
      }

      if (error instanceof Error) {
        await errorHandler(error, Auth.logoutAfterError)
        return
      }
      log.debug("Unexpected error", error)
    }
  },

  postLogin: async (token: CustomToken) => {
    if (token.idTokenClaims?.given_name) {
      userFirstNameKey = token.idTokenClaims.given_name
    }
    if (token.idTokenClaims?.extension_ProviderCode) {
      userProviderIdKey = token.idTokenClaims.extension_ProviderCode
    }
    if (token.idTokenClaims?.extension_IsAdministrator) {
      userIsAdmin = token.idTokenClaims.extension_IsAdministrator
    }
    if (token.idTokenClaims?.extension_ExternalContactId) {
      extensionExternalContactId =
        token.idTokenClaims.extension_ExternalContactId
    }
    tokenKey = token.accessToken
    userUniqueId = token.uniqueId
  },

  getToken: () => tokenKey,
  getUserFirstName: () => userFirstNameKey,
  getProviderId: () => userProviderIdKey,
  getUserUniqueId: () => userUniqueId,
  getUserIsAdmin: () => userIsAdmin,
  getExternalContactId: () => extensionExternalContactId,

  resetPassword: async () => {
    const { scopes } = req
    await msalApp.loginRedirect({
      scopes,
      authority: `https://${process.env.REACT_APP_AUTH_AUTHORITYSUB}.b2clogin.com/${process.env.REACT_APP_AUTH_AUTHORITYSUB}.onmicrosoft.com/${process.env.REACT_APP_AUTH_RESETFLOW}`
    })
  },

  signOut: async () => {
    await Auth.removeToken()
    log.debug(
      "As we are signing out, origin for redirect: ",
      window.location.origin
    )
    await msalApp.logoutRedirect()
  }
}

export default Auth
