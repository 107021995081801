import { PageLocalStorageType } from "utils/constants/localStorage.constants"

export interface IPageLocalStorageContextProvider {
  children?: React.ReactNode
  userId: string
}

// User Types

export interface IPageLocalStorageAction {
  type: keyof typeof PAGE_LOCAL_STORAGE_ACTIONS
  payload: PageLocalStorageType
}

export const PAGE_LOCAL_STORAGE_ACTIONS = {
  SET_PAGE_LOCAL_STORAGE: "SET_PAGE_LOCAL_STORAGE"
} as const
