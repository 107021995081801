import DriversLayout from "components/Drivers/DriversLayout"
import useUserContext from "hooks/useUserContext"
import { lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import AppLayout from "./AppLayout"
import RequireAuth from "./auth/RequireAuth"
import SalesforceUserLayout from "./components/Users/SalesforceUserLayout"
import {
  IS_BROADCAST,
  IS_DEV_ENVIRONMENT,
  IS_OPS_USER,
  ROUTES
} from "./utils/constants"

const Schedule = lazy(() => import("./screens/Schedule"))
const Drivers = lazy(() => import("./screens/Drivers"))
const DriversDetails = lazy(() => import("./screens/DriversDetails"))
const Users = lazy(() => import("./screens/Users"))
const NotFound = lazy(() => import("./screens/NotFound"))
const VehicleRun = lazy(() => import("./screens/VehicleRun"))
const AssignDriver = lazy(() => import("./components/AssignDriver"))
const TripOffersOPS = lazy(() => import("./screens/TripOffersOPS"))
const OfferDetailsSP = lazy(() => import("./screens/OfferDetailsSP"))
const OfferDetailsOPS = lazy(() => import("./screens/OfferDetailsOPS"))
const TripOffersSP = lazy(() => import("./screens/TripOffersSP"))
const Resources = lazy(() => import("./screens/Resources"))

function App() {
  const {
    SCHEDULE,
    DRIVERS,
    VEHICLE_RUN,
    USERS,
    ASSIGN_DRIVER,
    TRIP_OFFERS_OPS,
    TRIP_OFFERS_SP,
    OFFERS_DETAILS_SP,
    RESOURCES,
    OFFERS_DETAILS_OPS
  } = ROUTES

  const { userIsAdmin } = useUserContext()

  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<SalesforceUserLayout />} path="/">
          <Route element={<AppLayout />} path="/">
            <Route index element={<Navigate to={SCHEDULE} />} />
            <Route element={<Schedule />} path={SCHEDULE} />
            <Route element={<DriversLayout />} path={DRIVERS}>
              <Route index element={<Drivers />} />
              <Route element={<DriversDetails />} path=":id" />
            </Route>
            {userIsAdmin && <Route element={<Users />} path={USERS} />}
            {IS_DEV_ENVIRONMENT && userIsAdmin && IS_BROADCAST && (
              <>
                <Route element={<TripOffersSP />} path={TRIP_OFFERS_SP} />
                <Route
                  element={<OfferDetailsSP />}
                  path={`${OFFERS_DETAILS_SP}/:id`}
                />
              </>
            )}
            {IS_DEV_ENVIRONMENT && userIsAdmin && IS_OPS_USER && (
              <>
                <Route element={<TripOffersOPS />} path={TRIP_OFFERS_OPS} />
                <Route
                  element={<OfferDetailsOPS />}
                  path={`${OFFERS_DETAILS_OPS}/:id`}
                />
              </>
            )}
            <Route element={<Resources />} path={RESOURCES} />
            <Route element={<VehicleRun />} path={`${VEHICLE_RUN}/:id`} />

            <Route element={<AssignDriver />} path={ASSIGN_DRIVER} />
            <Route element={<NotFound />} path={ROUTES[404]} />
            <Route element={<Navigate to={ROUTES[404]} />} path="*" />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App
