import { MenuItem, SelectChangeEvent } from "@mui/material"
import useLanguage from "hooks/useLanguage"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { languages, ROUTES } from "utils/constants"

import {
  RightBox,
  RightBoxLink,
  Root,
  StyledNavLink,
  StyledOpenInNewIcon,
  StyledSelect
} from "./LeftMenu.styles"
import { ILeftMenu } from "./LeftMenu.types"

const LeftMenu: FC<ILeftMenu> = (props) => {
  const { t } = useTranslation("global")
  const { linkList } = props
  const { language, setLanguage } = useLanguage()

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    setLanguage(event.target.value as string)
  }

  return (
    <Root>
      <RightBox data-testid="RightNavigation">
        <StyledNavLink data-testid="ResourcesLink" to={ROUTES.RESOURCES}>
          {t("component.LeftMenu.resources")}
        </StyledNavLink>
        {linkList.map(({ displayName, href, external }, index) => {
          const linkKey = `link-key-${displayName}-${index}`

          return (
            <RightBoxLink
              href={href}
              key={linkKey}
              rel="noopener noreferrer"
              target="_blank"
              underline="none"
              variant="button"
            >
              {displayName}
              {external && <StyledOpenInNewIcon />}
            </RightBoxLink>
          )
        })}
        <StyledSelect
          data-testid="language"
          label=""
          value={language}
          onChange={handleLanguageChange}
        >
          {languages.map((languageItem) => {
            return (
              <MenuItem key={languageItem.code} value={languageItem.code}>
                {languageItem.name}
              </MenuItem>
            )
          })}
        </StyledSelect>
      </RightBox>
    </Root>
  )
}

export default LeftMenu
