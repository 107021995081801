import i18next from "i18next"
import { useEffect } from "react"
import { initialLanguage } from "utils/constants"

import useLocalStorage from "./useLocalStorage"
import useUserContext from "./useUserContext"

const useLanguage = () => {
  const { value: currentLanguage, handleValueChange: setLanguageLocalStore } =
    useLocalStorage("currentLanguage", initialLanguage)
  const { appLanguage, setAppLanguage } = useUserContext()

  const setLanguage = (newLanguage: string) => {
    setLanguageLocalStore(newLanguage)
    setAppLanguage(newLanguage)
    i18next.changeLanguage(newLanguage)
  }

  useEffect(() => {
    if (currentLanguage !== appLanguage) {
      setLanguage(currentLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    language: appLanguage,
    setLanguage
  }
}

export default useLanguage
