import {
  IUserAction,
  IUserState,
  USER_ACTIONS
} from "contexts/UserContext/UserContext.types"
import { initialLanguage } from "utils/constants/constants"

export const initialUserState: IUserState = {
  userFirstName: "",
  loginToken: "",
  providerId: "",
  appLanguage: initialLanguage,
  userUniqueId: "",
  userIsAdmin: false,
  salesforceAuthenticated: false
}

const userReducer = (state: IUserState, action: IUserAction): IUserState => {
  switch (action.type) {
    case USER_ACTIONS.SET_USER_FIRST_NAME:
      return {
        ...state,
        userFirstName: action.payload as string
      }
    case USER_ACTIONS.SET_TOKEN:
      return {
        ...state,
        loginToken: action.payload as string
      }
    case USER_ACTIONS.SET_PROVIDER_ID:
      return {
        ...state,
        providerId: action.payload as string
      }
    case USER_ACTIONS.SET_APP_LANGUAGE:
      return {
        ...state,
        appLanguage: action.payload as string
      }
    case USER_ACTIONS.SET_USER_UNIQUE_ID:
      return {
        ...state,
        userUniqueId: action.payload as string
      }
    case USER_ACTIONS.SET_USER_IS_ADMIN:
      return {
        ...state,
        userIsAdmin: action.payload as boolean
      }
    case USER_ACTIONS.SET_SALESFORCE_AUTHENTICATED:
      return {
        ...state,
        salesforceAuthenticated: action.payload as boolean
      }

    default:
      return state
  }
}

export default userReducer
