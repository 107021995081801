import { StyledLayoutBox } from "components/Layout/Layout.styles"

import {
  LoadingSkeletonContainer,
  StyledEverDrivenLogo,
  StyledLinearProgress
} from "./LoadingSkeleton.styles"

const LoadingSkeleton = () => {
  return (
    <StyledLayoutBox>
      <LoadingSkeletonContainer data-testid="loading">
        <StyledEverDrivenLogo />
        <StyledLinearProgress />
      </LoadingSkeletonContainer>
    </StyledLayoutBox>
  )
}

export default LoadingSkeleton
