import { createTheme } from "@mui/material"
import * as locales from "@mui/material/locale"
import { ThemeProvider } from "@mui/material/styles"
import Layout from "components/Layout"
import LoadingSkeleton from "components/shared/LoadingSkeleton"
import useLanguage from "hooks/useLanguage"
import useLocalStorage from "hooks/useLocalStorage"
import useUserContext from "hooks/useUserContext"
import { FC, Suspense, useEffect, useMemo, useState } from "react"
import { Outlet } from "react-router-dom"
import theme from "theme"
import { APP } from "utils/constants"
import { PAGES_LOCAL_STORAGE } from "utils/constants/localStorage.constants"

import { PageLocalStorageContextProvider } from "./contexts/PageLocalStorageContext/PageLocalStorageContextProvider"

type SupportedLocales = keyof typeof locales

const AppLayout: FC = () => {
  const { language } = useLanguage()

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[language as SupportedLocales]),
    [language]
  )

  const { userUniqueId } = useUserContext()
  const { value: appVersion, handleValueChange: handleAppVersionChange } =
    useLocalStorage(PAGES_LOCAL_STORAGE.APP_VERSION, APP.NO_VERSION)

  const { value: firstLogin, handleValueChange: handleFirstLoginChange } =
    useLocalStorage(PAGES_LOCAL_STORAGE.FIRST_LOGIN, APP.FIRST_LOGIN)

  const [currentFirstLogin, setCurrentFirstLogin] = useState(firstLogin)

  if (appVersion !== APP.VERSION) {
    localStorage.clear()
    handleAppVersionChange(APP.VERSION)
    handleFirstLoginChange(currentFirstLogin)
  }

  useEffect(() => {
    setCurrentFirstLogin(firstLogin)
  }, [firstLogin])

  return (
    <PageLocalStorageContextProvider userId={userUniqueId}>
      <ThemeProvider theme={themeWithLocale}>
        <Layout>
          <Suspense fallback={<LoadingSkeleton />}>
            <Outlet />
          </Suspense>
        </Layout>
      </ThemeProvider>
    </PageLocalStorageContextProvider>
  )
}

export default AppLayout
