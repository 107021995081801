import useUserContext from "hooks/useUserContext"

const useProviderVars = () => {
  const { providerId } = useUserContext()

  return {
    mrmProvider: providerId,
    providerId,
    sortBy: undefined,
    sortAsc: false,
    startDate: "2021-11-01T00:00:00Z",
    endDate: "2021-12-01T00:00:00Z",
    bypassPagination: false,
    pageNumber: 0,
    maxItemCount: 10,
    driverName: ""
  }
}

export default useProviderVars
