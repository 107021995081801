import {
  GetSalesforceUserInfoQuery,
  OutputProviderPayloadType
} from "generated/graphql"

interface ISalesforceUserLayoutAdapter {
  dataAPI?: GetSalesforceUserInfoQuery
}

const salesforceUserLayoutAdapter = ({
  dataAPI
}: ISalesforceUserLayoutAdapter) => {
  const salesforceUserInfo =
    dataAPI?.provider?.payload ?? ({} as OutputProviderPayloadType)
  const { providerName, externalId, externalSource } = salesforceUserInfo

  return {
    providerName,
    externalId,
    externalSource
  }
}

export default salesforceUserLayoutAdapter
