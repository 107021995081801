import { USER_ACTIONS } from "contexts/UserContext/UserContext.types"

import {
  useUserContextDispatch,
  useUserStateDispatch
} from "../contexts/UserContext/UserContextProvider"

const useUserContext = () => {
  const {
    userFirstName,
    loginToken,
    providerId,
    appLanguage,
    userUniqueId,
    userIsAdmin,
    salesforceAuthenticated
  } = useUserStateDispatch()

  const dispatch = useUserContextDispatch()

  const setUserFirstName = (newUserFirstName: string) => {
    dispatch({
      type: USER_ACTIONS.SET_USER_FIRST_NAME,
      payload: newUserFirstName
    })
  }

  const setLoginToken = (newLoginToken: string) => {
    dispatch({
      type: USER_ACTIONS.SET_TOKEN,
      payload: newLoginToken
    })
  }

  const setProviderId = (newProviderId: string) => {
    dispatch({
      type: USER_ACTIONS.SET_PROVIDER_ID,
      payload: newProviderId
    })
  }

  const setAppLanguage = (newAppLanguage: string) => {
    dispatch({
      type: USER_ACTIONS.SET_APP_LANGUAGE,
      payload: newAppLanguage
    })
  }

  const setUserUniqueId = (newUserUniqueId: string) => {
    dispatch({
      type: USER_ACTIONS.SET_USER_UNIQUE_ID,
      payload: newUserUniqueId
    })
  }

  const setUserIsAdmin = (newUserIsAdmin: boolean) => {
    dispatch({
      type: USER_ACTIONS.SET_USER_IS_ADMIN,
      payload: newUserIsAdmin
    })
  }

  const setSalesforceAuthenticated = (isAuthenticated: boolean) => {
    dispatch({
      type: USER_ACTIONS.SET_SALESFORCE_AUTHENTICATED,
      payload: isAuthenticated
    })
  }

  return {
    userFirstName,
    setUserFirstName,
    loginToken,
    setLoginToken,
    providerId,
    setProviderId,
    appLanguage,
    setAppLanguage,
    userUniqueId,
    setUserUniqueId,
    userIsAdmin,
    setUserIsAdmin,
    salesforceAuthenticated,
    setSalesforceAuthenticated
  }
}

export default useUserContext
