import { LinearProgress, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactComponent as EverDrivenLogo } from "assets/images/everDrivenLogoRGB.svg"

export const LoadingSkeletonContainer = styled(Stack)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1.5),
  width: "70%",
  borderRadius: theme.shape.borderRadius
}))

export const StyledEverDrivenLogo = styled(EverDrivenLogo)(({ theme }) => ({
  width: "70%",
  marginBottom: theme.spacing(4)
}))
