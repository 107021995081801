import { createContext, FC, useContext, useReducer } from "react"

import userReducer, { initialUserState } from "./reducers/userReducer"
import { IContextProvider, IUserAction, IUserState } from "./UserContext.types"

export const UserDispatchContext = createContext<React.Dispatch<IUserAction>>(
  () => {}
)
export const UserStateContext = createContext<IUserState>(initialUserState)

export const UserContextProvider: FC<IContextProvider> = ({
  children = []
}) => {
  const [state, dispatch] = useReducer(userReducer, initialUserState)

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  )
}

export const useUserContextDispatch = () => useContext(UserDispatchContext)
export const useUserStateDispatch = () => useContext(UserStateContext)
