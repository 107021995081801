import ReactGA from "react-ga-neo"

import { ISendToAnalytics } from "./analytics.types"

export function sendToAnalytics({ name, delta, value, id }: ISendToAnalytics) {
  ReactGA.event(name, {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta
  })
}
