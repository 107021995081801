import { FC, useState } from "react"

import Body from "./Body"
import Footer from "./Footer"
import Header from "./Header/index"
import { ILayout } from "./Layout.types"

const Layout: FC<ILayout> = ({ children = [] }) => {
  const [openDrawer, setOpenDrawer] = useState(true)

  return (
    <>
      <Header openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Body open={openDrawer}>{children}</Body>
      <Footer />
    </>
  )
}

export default Layout
